import { FC } from 'react'
import Menu from '../components/Menu/Menu'
import HeroSection from '../components/HeroSection'

const Home: FC = () => {
   return (
      <div className="relative">
         <HeroSection />
         <Menu />
      </div>
   )
}

export default Home
