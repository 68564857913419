export type TranslationKeys = {
   [key: string]: string
}

export const tr: TranslationKeys = {
   // Navigation
   'nav.home': 'Ana Sayfa',
   'nav.about': 'Hakkımızda',
   'nav.gallery': 'Galeri',
   // About Us Page
   'about.title': 'Biz Kimiz?',
   'about.paragraph1':
      'Menüsü rafine Akdeniz sahil yemeklerinin yorumlanmasından oluşan Bobo Franco, gündüz saatlerinde kahve ve kahvaltı ağırlıklı menüsü ile şehrin merkezinde bulunan gizli bahçesinde hem güne başlamanıza hem de ruhunuzu dinlendirmeye yardımcı oluyor.',
   'about.paragraph2':
      'Akşam menüsü ise lezzetli kokteyllerinize eşlik ederek, arkadaşlarınızla paylaşabileceğiniz seçkin lezzetlerden oluşmakta.',
   'about.paragraph3':
      "Hikaye ve deneyim üzerine kurulu benzersiz ve dinlendirici bir gizli bahçenin ortasında Akdeniz'in özünü yansıtan ve tüm gün hizmet veren bobo'da eşsiz bir atmosfere davetlisiniz.",
   // Features Section
   'features.atmosphere.title': 'Atmosfer',
   'features.atmosphere.description':
      'Şehrin kalbinde, rutinin koşuşturmasından uzak, tarzın ve sanatın buluştuğu gizli bir Akdeniz bahçesi',
   'features.discover.title': 'Keşfedin',
   'features.discover.description':
      'Benzer ruhların buluştuğu, müzik ve sohbetle sarılan yeşil bir atmosfer. Eğlence ve stilin her an bir araya geldiği gastronomik bir deneyim',

   // Highlight Section
   'highlight.section.title': 'Lezzet',
   'highlight.section.description':
      'Günün her saati farklı bir lezzet deneyimi sunan bobo franco, misafirlerini avrupanın aperatif mutfağı ve rafine içeceklerini Adana samiyeti ile buluşturan düşünülmüş bir nokta. İşte onlardan bazıları',

   // Highlight Items
   'highlight.item1.title': 'Truffle Risotto',
   'highlight.item1.description':
      "Trüf mantarıyla tatlandırılmış risottomuz, güney İtalya'nın klasik lezzetini zarif bir şekilde yansıtıyor",
   'highlight.item2.title': "Franco's Kalamari",
   'highlight.item2.description':
      "Akdeniz'in özgün taze kalamarı ile kendinizi deniz kenarındaki bir lezzet kaçamağında hissedeceksiniz",
   'highlight.item3.title': 'The Perfect Espresso Martini',
   'highlight.item3.description':
      'Yoğun kahve aroması ve hafif kremamsı dokusuyla dinlendirici bir keyif sunar',

   // Events Section
   'events.store.name': 'Store',
   'events.store.description':
      "bobo franco zamansız mimarisi ile sanat eserleri ve yaratıcılığın buluştuğu, Adana merkezli çok amaçlı yaratıcı bir alandır. bobo franco'nun store'u koleksiyon değeri taşıyan, modern vintage ve yeni mobilya parçalarını, yerel sanatçıların çağdaş sanat eserlerini ve benzersiz tasarım objelerini sunarken, yaratıcılığın gelişebileceği özel bir alan yaratmaktadır.",
   'events.bar.name': 'Bar',
   'events.bar.description':
      "bobo franco'nun barı, sanatı ve tasarımı kutlarken aynı zamanda lezzetli içecekler sunan bir buluşma noktasıdır. Modern ve samimi bir atmosferde özenle hazırlanmış kokteyller, kahve spesiyalleri ve yerel lezzetler ile hem rahatlayabilir hem de yaratıcılığınızı besleyebilirsiniz.",
   'events.events.name': 'Etkinlikler',
   'events.events.description':
      "bobo franco'da düzenlenen pop-up partiler, workshoplar, sanat sergileri ve tasarım sohbetleri hem bobo franco üyelerini, hem sanatçıları, hem de sanatseverleri bir araya getirerek yaratıcılığı besler. İster bir sanat etkinliği düzenlemek için isterse yaratıcı projeleriniz için bir alan arıyor olun, bobo franco'nun dinamik ve eğlenceli atmosferi ilham verici bir deneyim sunar.",

   // Menu Categories
   'menu.category.food': 'YEMEKLER',
   'menu.category.coffee': 'KAHVELER',
   'menu.category.cocktails': 'KOKTEYLLER',
   'menu.category.whiskey': 'VİSKİLER',
   'menu.category.champagne': 'ŞAMPANYALAR',
   'menu.category.wine': 'ŞARAPLAR',
   'menu.category.gin': 'GİN',
   'menu.category.tequila': 'TEKİLA',
   'menu.category.vodka': 'VODKA',
   'menu.category.rum': 'RUM',
   'menu.category.cognac': 'KONYAK',
   'menu.category.vermouth': 'VERMOUTH',
   'menu.category.aquavit': 'AQUAVIT',
   'menu.category.liqueur': 'LİKÖRLER',
   'menu.category.beer': 'BİRA',
   'menu.category.tonic': 'TONİCLER',
   'menu.category.soft': 'SOFT İÇECEKLER',

   // Menu Subcategories - Food
   'menu.subcategory.food.appetizers': 'Aperatifler',
   'menu.subcategory.food.sandwiches': 'Sandviçler',
   'menu.subcategory.food.main_courses': 'Ana Yemekler',
   'menu.subcategory.food.desserts': 'Tatlılar',
   'menu.subcategory.food.breakfast': "Kahvaltılıklar (14:00'a kadar)",

   // Menu Subcategories - Coffee
   'menu.subcategory.coffee.hot': 'Sıcak',
   'menu.subcategory.coffee.cold': 'Soğuk',

   // Menu Subcategories - Cocktails
   'menu.subcategory.cocktails.classic': 'Klasik Kokteyller',
   'menu.subcategory.cocktails.twisted': 'Twist Kokteyller',
   'menu.subcategory.cocktails.signature': 'İmza Kokteyller',

   // Menu Subcategories - Whiskey
   'menu.subcategory.whiskey.blended': 'Blended Scotch',
   'menu.subcategory.whiskey.single': 'Single Malt',
   'menu.subcategory.whiskey.bourbon': 'Bourbon & Tennessee',
   'menu.subcategory.whiskey.irish': 'Irish',

   // Menu Subcategories - Wine
   'menu.subcategory.wine.red': 'Kırmızı Şaraplar',
   'menu.subcategory.wine.white': 'Beyaz Şaraplar',
   'menu.subcategory.wine.rose': 'Rose-Blush Şaraplar',
   'menu.subcategory.wine.dark_rose': 'Dark Rose Şaraplar',

   // Menu Items - Food - Appetizers
   'menu.item.food.sandwiches.fume_sandwich.name': 'Füme Sandwich',
   'menu.item.food.sandwiches.fume_sandwich.description':
      'Dana Füme, Kars Eski Kaşarı, Acı Biber Reçeli, Patates Cipsi',
   'menu.item.food.sandwiches.fume_sandwich.flavor': '',

   'menu.item.food.appetizers.chicken_popsicle.name': 'Chicken Popsicle',
   'menu.item.food.appetizers.chicken_popsicle.description':
      'Fine Blend Çıtır Tavuk-Çift aromatik Mayonez-Parmesan',
   'menu.item.food.appetizers.chicken_popsicle.flavor': '',

   'menu.item.food.main_courses.orzo_truffle.name': 'Orzo With Truffle Paste',
   'menu.item.food.main_courses.orzo_truffle.description':
      'Orzo Tekniği ile Pişirilmiş Arpa Şehriye, Trüf Ezmesi',
   'menu.item.food.main_courses.orzo_truffle.flavor': '',

   'menu.item.food.appetizers.levrek_filango.name': 'Levrek Filango',
   'menu.item.food.appetizers.levrek_filango.description':
      'Kiraz Sapı ile Tütsülenmiş Açık Deniz OIta Levreği Dilimleri-Ananas ve Limon Asidi-Aromatik Yağlar (Isıl işlem görmemiştir, soğuk servis.)',
   'menu.item.food.appetizers.levrek_filango.flavor': '',

   'menu.item.food.main_courses.topluma_levrek.name':
      'Topluma Kazandırılmış Levrek',
   'menu.item.food.main_courses.topluma_levrek.description':
      '120 gr Kılçıksız Levrek Fileto, Baby Patates',
   'menu.item.food.main_courses.topluma_levrek.flavor': '',

   'menu.item.food.sandwiches.open_face_tuna.name': 'Open Face Tuna',
   'menu.item.food.sandwiches.open_face_tuna.description':
      'Yeşil Soğan Mayonezi ile Bağlanmış Ton Balığı, Ayçekirdeği ve Haşhaşlı Ekmek, Salamura Domates, Kırmızı Soğan Turşusu',
   'menu.item.food.sandwiches.open_face_tuna.flavor': '',

   // Menu Items - Food - Desserts
   'menu.item.food.desserts.lemon_cheesecake.name': 'Lemon Curd Cheesecake',
   'menu.item.food.desserts.lemon_cheesecake.description':
      'Limon Kremalı Cheesecake',
   'menu.item.food.desserts.lemon_cheesecake.flavor': '',

   'menu.item.food.desserts.paris_brest.name': 'Paris Brest',
   'menu.item.food.desserts.paris_brest.description':
      'Pate a Choux Hamuru İçerisinde Antep Fıstıklı Pastacı Kreması',
   'menu.item.food.desserts.paris_brest.flavor': '',

   'menu.item.food.breakfast.cheese_omelette.name': 'Peynirli Omlet',
   'menu.item.food.breakfast.cheese_omelette.description': '',
   'menu.item.food.breakfast.cheese_omelette.flavor': '',

   'menu.item.food.breakfast.pastrami_omelette.name': 'Pastırmalı Omlet',
   'menu.item.food.breakfast.pastrami_omelette.description': '',
   'menu.item.food.breakfast.pastrami_omelette.flavor': '',

   'menu.item.food.breakfast.sausage_omelette.name': 'Frankfurter Omlet',
   'menu.item.food.breakfast.sausage_omelette.description': '',
   'menu.item.food.breakfast.sausage_omelette.flavor': '',

   // Menu Items - Coffee - Hot
   'menu.item.coffee.hot.espresso.name': 'Espresso',
   'menu.item.coffee.hot.espresso.description':
      'Dengeli asidite, hafif tatlılık ve fındık, kahve ve çikolata notaları.',
   'menu.item.coffee.hot.espresso.flavor': '',

   'menu.item.coffee.hot.americano.name': 'Americano',
   'menu.item.coffee.hot.americano.description':
      'Dengeli asidite, hafif tatlılık ve fındık, kahve ve çikolata notaları.',
   'menu.item.coffee.hot.americano.flavor': '',

   'menu.item.coffee.hot.coffee_latte.name': 'Coffee Latte',
   'menu.item.coffee.hot.coffee_latte.description':
      'Orta uzunlukta, tatlı ve fındıklı bir bitiş.',
   'menu.item.coffee.hot.coffee_latte.flavor': '',

   'menu.item.coffee.hot.cappuccino.name': 'Cappuccino',
   'menu.item.coffee.hot.cappuccino.description':
      'Dengeli asidite, hafif tatlılık ve fındık, kahve ve çikolata notaları.',
   'menu.item.coffee.hot.cappuccino.flavor': '',

   'menu.item.coffee.hot.flat_white.name': 'Flat White',
   'menu.item.coffee.hot.flat_white.description': '',
   'menu.item.coffee.hot.flat_white.flavor': '',

   'menu.item.coffee.hot.cortado.name': 'Cortado',
   'menu.item.coffee.hot.cortado.description': '',
   'menu.item.coffee.hot.cortado.flavor': '',

   'menu.item.coffee.hot.turkish_coffee.name': 'Türk Kahvesi',
   'menu.item.coffee.hot.turkish_coffee.description': '',
   'menu.item.coffee.hot.turkish_coffee.flavor': '',

   'menu.item.coffee.hot.filter_coffee.name': 'Filtre kahve',
   'menu.item.coffee.hot.filter_coffee.description':
      'Dengeli asidite, hafif tatlılık ve fındık, kahve ve çikolata notaları.',
   'menu.item.coffee.hot.filter_coffee.flavor': '',

   'menu.item.coffee.hot.tea.name': 'Çay',
   'menu.item.coffee.hot.tea.description': '',
   'menu.item.coffee.hot.tea.flavor': '',

   // Menu Items - Coffee - Cold
   'menu.item.coffee.cold.ice_americano.name': 'Ice Americano',
   'menu.item.coffee.cold.ice_americano.description':
      'Fındık, hafif çikolata ve karamel kokuları.',
   'menu.item.coffee.cold.ice_americano.flavor': '',

   'menu.item.coffee.cold.ice_latte.name': 'Ice Latte',
   'menu.item.coffee.cold.ice_latte.description':
      'Fındık, hafif çikolata ve karamel kokuları.',
   'menu.item.coffee.cold.ice_latte.flavor': '',

   // Menu Items - Cocktails - Classic
   'menu.item.cocktails.classic.whiskey_sour.name': 'WHISKY SOUR',
   'menu.item.cocktails.classic.whiskey_sour.description':
      'Ekşi, kremamsı, dengeli.',
   'menu.item.cocktails.classic.whiskey_sour.flavor':
      'JW BLACK LABEL, LİMON SUYU, ŞEKER ŞURUBU, YUMURTA AKI',

   'menu.item.cocktails.classic.dirty_martini.name': 'DIRTY MARTINI',
   'menu.item.cocktails.classic.dirty_martini.description':
      'Tuzlu, kuru, dengeli.',
   'menu.item.cocktails.classic.dirty_martini.flavor':
      'TANQUREAY LONDON DRY GİN, GARRONE BIANCO, SALAMURA ZEYTİN SUYU',

   'menu.item.cocktails.classic.penicillin.name': 'PENICILLIN',
   'menu.item.cocktails.classic.penicillin.description':
      'İsli, baharatlı, zencefil.',
   'menu.item.cocktails.classic.penicillin.flavor':
      'JW BLACK LABEL, ARDBEG WEE BEASTIE, TAZE ZENCEFİL SUYU, LİMON SUYU, ÇAM BALI',

   'menu.item.cocktails.classic.gin_basil.name': 'GIN BASIL SMASH',
   'menu.item.cocktails.classic.gin_basil.description':
      'Ferah, bitkisel, ekşi.',
   'menu.item.cocktails.classic.gin_basil.flavor':
      'TANQUREAY LONDON DRY GİN, TAZE FESLEĞEN, ŞEKER ŞURUBU, LİMON SUYU',

   'menu.item.cocktails.classic.espresso_martini.name': 'ESPRESSO MARTINI',
   'menu.item.cocktails.classic.espresso_martini.description':
      'Kahve, kremsi, tatlı.',
   'menu.item.cocktails.classic.espresso_martini.flavor':
      'KETEL ONE VODKA, KAHLUA COFFEE LİKÖRÜ, ESPRESSO',

   'menu.item.cocktails.classic.dutch_mule.name': 'DUTCH MULE',
   'menu.item.cocktails.classic.dutch_mule.description':
      'Baharatlı, taze, hafif ekşi.',
   'menu.item.cocktails.classic.dutch_mule.flavor':
      'KETEL ONE VODKA, AALBORG TAFFELL AQUAVIT, LİME SUYU, ANGOUSTRA BİTTER, ZENCEFİLLİ GAZOZ',

   'menu.item.cocktails.classic.mimosa.name': 'MIMOSA',
   'menu.item.cocktails.classic.mimosa.description':
      'Portakal, taze, hafif tatlı',
   'menu.item.cocktails.classic.mimosa.flavor':
      'RUFFINO PROSECCO, TAZE PORTAKAL SUYU',

   'menu.item.cocktails.classic.bellini.name': 'BELLINI',
   'menu.item.cocktails.classic.bellini.description':
      'Şeftali, köpüklü, tatlı.',
   'menu.item.cocktails.classic.bellini.flavor':
      'RUFFINO PROSECCO, ŞEFTALİ PÜRESİ',

   'menu.item.cocktails.classic.tommys_margarita.name': "TOMMY'S MARGARITA",
   'menu.item.cocktails.classic.tommys_margarita.description':
      'Tropikal, ekşi, agave.',
   'menu.item.cocktails.classic.tommys_margarita.flavor':
      'CASAMİGOS BLANCO, AGAVE NECTARIN, LİME SUYU',

   'menu.item.cocktails.classic.paloma.name': 'PALOMA',
   'menu.item.cocktails.classic.paloma.description':
      'Ferah, narenciye, hafif tatlı.',
   'menu.item.cocktails.classic.paloma.flavor':
      'CASAMİGOS BLANCO, GREYFURT SUYU, LİMON SUYU, AGAVE NECTARIN, PINK GREYFURT SODA',

   'menu.item.cocktails.classic.negroni.name': 'NEGRONI',
   'menu.item.cocktails.classic.negroni.description':
      'Bitter, baharatlı, dengeli.',
   'menu.item.cocktails.classic.negroni.flavor':
      'TANQUREAY LONDON DRY GİN, CAMPARI BİTTER LİKÖR, GARRONE ROSSO VERMOUTH',

   'menu.item.cocktails.classic.old_cuban.name': 'OLD CUBAN HIGHBALL',
   'menu.item.cocktails.classic.old_cuban.description':
      'Narenciye, taze, köpüklü.',
   'menu.item.cocktails.classic.old_cuban.flavor':
      'CAPTAİN MORGAN GOLD ROM, LİME SUYU, ŞEKER ŞURUBU, RUFFINO PROSECCO, ANGOUSTURA BİTTER',

   // Menu Items - Cocktails - Twisted
   'menu.item.cocktails.twisted.cool_franco_spritz.name': 'COOL FRANCO SPRITZ',
   'menu.item.cocktails.twisted.cool_franco_spritz.description':
      'Meyvemsi, bitkisel, köpüklü',
   'menu.item.cocktails.twisted.cool_franco_spritz.flavor':
      'İnfused Adaçayı Çilek Aperol, Vinkara Dömisek Bornova, Dry Tonic Water',

   'menu.item.cocktails.twisted.bobo_star_martini.name': 'BOBO STAR MARTINI',
   'menu.item.cocktails.twisted.bobo_star_martini.description':
      'Tropikal, baharatlı, kompleks.',
   'menu.item.cocktails.twisted.bobo_star_martini.flavor':
      'JW Black Label, Passion Fruit, Akçaağaç, Sumak, Worcestershire, İsot, Citrus Blend',

   'menu.item.cocktails.twisted.cosalinha.name': 'COSALINHA',
   'menu.item.cocktails.twisted.cosalinha.description':
      'İsli, narenciye, tatlı.',
   'menu.item.cocktails.twisted.cosalinha.flavor':
      'Casamigos Mezcal, Lime Suyu, Çam Balı',

   'menu.item.cocktails.twisted.butterfly_daiquiri.name': 'BUTTERFLY DAIQUIRI',
   'menu.item.cocktails.twisted.butterfly_daiquiri.description':
      'Çiçeksi, narenciye, hafif tatlı',
   'menu.item.cocktails.twisted.butterfly_daiquiri.flavor':
      'Infused Butterfly Tea, Captain Morgan White Rum, Portakal Likörü, Greyfurt ve Mandalina Suyu',

   'menu.item.cocktails.twisted.rock_n_roll.name': "ROCK'N ROLL",
   'menu.item.cocktails.twisted.rock_n_roll.description':
      'Ekşi, baharatlı, tatlı',
   'menu.item.cocktails.twisted.rock_n_roll.flavor':
      'JW Black Label, Bottega Limoncello, Zencefil Suyu, Akçaağaç Şurubu, Limon Suyu, Pink Greyfurt Tonic',

   'menu.item.cocktails.twisted.melon_garibaldi.name': 'MELON GARIBALDI',
   'menu.item.cocktails.twisted.melon_garibaldi.description':
      'Meyvemsi, bitter, hafif tatlı',
   'menu.item.cocktails.twisted.melon_garibaldi.flavor':
      'Bottega Melon, Campari, Akçaağaç Şurubu, Taze Yeşil Elma Suyu, Portakal Suyu, Limon Suyu, Angostura Bitter ',

   'menu.item.cocktails.twisted.cane_coco.name': 'CANE & COCO',
   'menu.item.cocktails.twisted.cane_coco.description':
      'İsli, kahvemsi, narenciye',
   'menu.item.cocktails.twisted.cane_coco.flavor':
      'Casamigos Blanco Tekila, Infused Arabica Coffee Tarçın Malibu, Angostura Bitter, Thomas Henry Mango, Citrus Blend',

   // Menu Items - Cocktails - Signature
   'menu.item.cocktails.signature.monik.name': 'MONIK',
   'menu.item.cocktails.signature.monik.description':
      'Narenciye, bitkisel, tatlı',
   'menu.item.cocktails.signature.monik.flavor':
      'Ketel One Vodka, Rezene, Hünnap, Limon Suyu',

   'menu.item.cocktails.signature.06.name': '06',
   'menu.item.cocktails.signature.06.description': 'Ekşi, umami',
   'menu.item.cocktails.signature.06.flavor':
      'Tanqueray London Dry Gin, Yeşil Elma Kuzu Kulağı, Citrus Blend, Çöven Otu',

   'menu.item.cocktails.signature.01.name': '01',
   'menu.item.cocktails.signature.01.description': 'Ekşi, şeffaf, sert',
   'menu.item.cocktails.signature.01.flavor':
      'Fat Washed Casamigos Blanco Tekila, Clear Ayva Suyu, Clear Limon Suyu, Tatlı Domates, Dere Otu',

   'menu.item.cocktails.signature.02.name': '02',
   'menu.item.cocktails.signature.02.description': 'Tatlı, acı',
   'menu.item.cocktails.signature.02.flavor':
      'JW Black Label, Mango, Jalapeno, Tarhun, Citrus Blend, Çöven Otu',

   'menu.item.cocktails.signature.05.name': '05',
   'menu.item.cocktails.signature.05.description': 'Tatlı, dengeli',
   'menu.item.cocktails.signature.05.flavor':
      'Bulliet Bourbon, Bottega Amaro Elixir, Vişne, Tuzlu Badem, Kavrulmuş Menengiç, Çöven Otu, Citrus Blend',

   'menu.item.cocktails.signature.07.name': '07',
   'menu.item.cocktails.signature.07.description': 'Tatlı, baharatlı',
   'menu.item.cocktails.signature.07.flavor':
      'Singleton 12 of Dufftown, Bottega Melon, Böğürtlen, Kereviz, Yıldız Anason, Citrus Blend',

   // Turkish Translations
   'menu.item.cocktails.signature.03.name': '03',
   'menu.item.cocktails.signature.03.description': 'Meyvemsi, bitter',
   'menu.item.cocktails.signature.03.flavor':
      'Fatwashed Casamigos Blanco Vişne Frambuaz Kakao, Campari, Clear Ayva Suyu, Gazoz',

   // Menu Items - Whiskey - Blended Scotch
   'menu.item.whiskey.blended.dimple_golden.name': 'Dimple Golden Selection',
   'menu.item.whiskey.blended.dimple_golden.description':
      'Yumuşak, narenciye, hafif',
   'menu.item.whiskey.blended.dimple_golden.flavor': '',

   'menu.item.whiskey.blended.jw_black.name': 'Johnnie Walker Black Label',
   'menu.item.whiskey.blended.jw_black.description':
      'Dumanlı, meyvemsi, dengeli',
   'menu.item.whiskey.blended.jw_black.flavor': '',

   'menu.item.whiskey.blended.jw_double_black.name':
      'Johnnie Walker Double Black',
   'menu.item.whiskey.blended.jw_double_black.description':
      'Yoğun, odunsu, baharatlı',
   'menu.item.whiskey.blended.jw_double_black.flavor': '',

   'menu.item.whiskey.blended.jw_blue.name': 'Johnnie Walker Blue Label',
   'menu.item.whiskey.blended.jw_blue.description': 'Pürüzsüz, lüks, derin',
   'menu.item.whiskey.blended.jw_blue.flavor': '',

   'menu.item.whiskey.blended.jw_king_george.name':
      'Johnnie Walker Sons King George',
   'menu.item.whiskey.blended.jw_king_george.description':
      'Yoğun, zarif, tütsü',
   'menu.item.whiskey.blended.jw_king_george.flavor': '',

   'menu.item.whiskey.blended.jw_18.name': 'Johnnie Walker Aged 18 YO',
   'menu.item.whiskey.blended.jw_18.description': 'Pürüzsüz, zengin, karamel',
   'menu.item.whiskey.blended.jw_18.flavor': '',

   // Menu Items - Whiskey - Single Malt
   'menu.item.whiskey.single.singleton_12.name':
      'Singleton of Dufftown 12 Years Old',
   'menu.item.whiskey.single.singleton_12.description':
      'Şeftali, vanilya, hafif',
   'menu.item.whiskey.single.singleton_12.flavor': '',

   'menu.item.whiskey.single.ardbeg.name': 'Ardbeg Wee Beastie',
   'menu.item.whiskey.single.ardbeg.description': 'İsli, güçlü, yoğun',
   'menu.item.whiskey.single.ardbeg.flavor': '',

   'menu.item.whiskey.single.glenmorangie.name':
      'Glenmorangie Triple Cask Reserve',
   'menu.item.whiskey.single.glenmorangie.description':
      'Şeftali, tatlı baharat, odunsu',
   'menu.item.whiskey.single.glenmorangie.flavor': '',

   'menu.item.whiskey.single.cardhu_gold.name': 'Cardhu Gold Reserve',
   'menu.item.whiskey.single.cardhu_gold.description': '',
   'menu.item.whiskey.single.cardhu_gold.flavor': '',

   'menu.item.whiskey.single.talisker.name': 'Talisker 10 YO',
   'menu.item.whiskey.single.talisker.description': 'Dumanlı, biberli, deniz',
   'menu.item.whiskey.single.talisker.flavor': '',

   'menu.item.whiskey.single.singleton_15.name': 'Singleton 15 YO',
   'menu.item.whiskey.single.singleton_15.description': '',
   'menu.item.whiskey.single.singleton_15.flavor': '',

   'menu.item.whiskey.single.cardhu_15.name': 'Cardhu 15 YO',
   'menu.item.whiskey.single.cardhu_15.description': 'Bal, elma, tatlı',
   'menu.item.whiskey.single.cardhu_15.flavor': '',

   'menu.item.whiskey.single.oban.name': 'Oban 10 Years Old',
   'menu.item.whiskey.single.oban.description': 'Deniz, turunçgil, hafif isli',
   'menu.item.whiskey.single.oban.flavor': '',

   'menu.item.whiskey.single.mortlach.name': 'Mortlach 16 Years Old',
   'menu.item.whiskey.single.mortlach.description': 'Kompleks, etli, baharatlı',
   'menu.item.whiskey.single.mortlach.flavor': '',

   'menu.item.whiskey.single.clynelish_12.name': 'Clynelish 12 Years Old',
   'menu.item.whiskey.single.clynelish_12.description':
      'Balmumu, tropikal, baharat',
   'menu.item.whiskey.single.clynelish_12.flavor': '',

   'menu.item.whiskey.single.clynelish_14.name': 'Clynelish 14 Years Old',
   'menu.item.whiskey.single.clynelish_14.description':
      'Balmumu, tropikal, baharat',
   'menu.item.whiskey.single.clynelish_14.flavor': '',

   'menu.item.whiskey.single.cardhu_16.name': 'Cardhu 16 YO',
   'menu.item.whiskey.single.cardhu_16.description': '',
   'menu.item.whiskey.single.cardhu_16.flavor': '',

   'menu.item.whiskey.single.cameronbridge.name': 'Cameronbridge 26 YO',
   'menu.item.whiskey.single.cameronbridge.description': '',
   'menu.item.whiskey.single.cameronbridge.flavor': '',

   'menu.item.whiskey.single.nikka_malt.name': 'Nikka Coffey Malt',
   'menu.item.whiskey.single.nikka_malt.description':
      'Tatlı, kremamsı, baharatlı',
   'menu.item.whiskey.single.nikka_malt.flavor': '',

   'menu.item.whiskey.single.nikka_grain.name': 'Nikka Coffey Grain',
   'menu.item.whiskey.single.nikka_grain.description':
      'Tatlı, zencefil kremamsı',
   'menu.item.whiskey.single.nikka_grain.flavor': '',

   'menu.item.whiskey.single.caol_ila.name': 'Caol Ila 12 YO',
   'menu.item.whiskey.single.caol_ila.description': 'Turbo, limon, isli',
   'menu.item.whiskey.single.caol_ila.flavor': '',

   // Menu Items - Whiskey - Bourbon & Tennessee
   'menu.item.whiskey.bourbon.jack_daniels.name': "Jack Daniel's No. 7",
   'menu.item.whiskey.bourbon.jack_daniels.description':
      'Tatlı, dumanlı, hafif',
   'menu.item.whiskey.bourbon.jack_daniels.flavor': '',

   'menu.item.whiskey.bourbon.gentleman_jack.name': 'Gentleman Jack',
   'menu.item.whiskey.bourbon.gentleman_jack.description':
      'Yumuşak, vanilya, baharatlı',
   'menu.item.whiskey.bourbon.gentleman_jack.flavor': '',

   'menu.item.whiskey.bourbon.bulleit.name': 'Bulleit Bourbon',
   'menu.item.whiskey.bourbon.bulleit.description': 'Karamel, baharat, vanilya',
   'menu.item.whiskey.bourbon.bulleit.flavor': '',

   'menu.item.whiskey.bourbon.woodford.name': 'Woodford Reserve',
   'menu.item.whiskey.bourbon.woodford.description': 'Baharatlı, karamel, kuru',
   'menu.item.whiskey.bourbon.woodford.flavor': '',

   // Menu Items - Whiskey - Irish
   'menu.item.whiskey.irish.bushmills.name': 'Bushmills Originial',
   'menu.item.whiskey.irish.bushmills.description': 'Hafif, meyvemsi, pürüzsüz',
   'menu.item.whiskey.irish.bushmills.flavor': '',

   'menu.item.whiskey.irish.bushmills_10.name': 'Bushmills 10 YO',
   'menu.item.whiskey.irish.bushmills_10.description':
      'Bal, vanilya, narenciye',
   'menu.item.whiskey.irish.bushmills_10.flavor': '',

   'menu.item.whiskey.irish.bushmills_black.name': 'Bushmills Black',
   'menu.item.whiskey.irish.bushmills_black.description':
      'Kompleks, meşe, meyvemsi',
   'menu.item.whiskey.irish.bushmills_black.flavor': '',

   // Menu Items - Champagne
   'menu.item.champagne.chandon_garden.name': 'Chandon Garden Spritz',
   'menu.item.champagne.chandon_garden.description':
      'Baharatlı, portakal, bitkisel.',
   'menu.item.champagne.chandon_garden.flavor': 'Chardonnay, Pinot Noir',

   'menu.item.champagne.chandon_brut.name': 'Chandon Brut Sparkling Wine',
   'menu.item.champagne.chandon_brut.description': 'Taze, narenciye, mineral',
   'menu.item.champagne.chandon_brut.flavor':
      'Chardonnay, Pinot Noir, Pinot Meunier',

   'menu.item.champagne.ruffino.name': 'Ruffino Prosecco',
   'menu.item.champagne.ruffino.description': 'Hafif, narenciye, çiçeksi.',
   'menu.item.champagne.ruffino.flavor': 'Glera',

   'menu.item.champagne.moet_imperial.name':
      'Moet & Chandon Imperial Brut Champagne Şişe',
   'menu.item.champagne.moet_imperial.description': 'Canlı, meyvemsi, çiçeksi',
   'menu.item.champagne.moet_imperial.flavor':
      'Pinot Noir, Pinot Meunier, Chardonnay',

   'menu.item.champagne.moet_rose.name':
      'Moet & Chandon Rose Imperial Champagne Şişe',
   'menu.item.champagne.moet_rose.description': 'Çilek, nar, baharat',
   'menu.item.champagne.moet_rose.flavor':
      'Pinot Noir, Pinot Meunier, Chardonnay',

   // Menu Items - Wine - Red
   'menu.item.wine.red.vinkara_okuzgozu.name': 'Vinkara-Öküzgözü',
   'menu.item.wine.red.vinkara_okuzgozu.description':
      'Canlı, kırmızı meyve, hafif baharat',
   'menu.item.wine.red.vinkara_okuzgozu.flavor': '',

   'menu.item.wine.red.suvla_papaz.name': 'Suvla-Papaz Karası',
   'menu.item.wine.red.suvla_papaz.description': 'Yumuşak, meyvemsi, baharatlı',
   'menu.item.wine.red.suvla_papaz.flavor': '',

   'menu.item.wine.red.suvla_reserve.name':
      'Suvla-Reserve Cabernet Sauvignon Şişe',
   'menu.item.wine.red.suvla_reserve.description':
      'Yoğun, siyah meyve, topraksı',
   'menu.item.wine.red.suvla_reserve.flavor': '',

   'menu.item.wine.red.kayra_terra.name':
      'Kayra Terra Varietal Öküzgözü Boğazkere',
   'menu.item.wine.red.kayra_terra.description': 'Dengeli, baharatlı',
   'menu.item.wine.red.kayra_terra.flavor': '',

   'menu.item.wine.red.cielo_merlot.name': 'Cielo Merlot',
   'menu.item.wine.red.cielo_merlot.description': 'Yumuşak, kırmızı meyve',
   'menu.item.wine.red.cielo_merlot.flavor': '',

   'menu.item.wine.red.cielo_cabernet.name': 'Cielo Cabernet Sauvignon',
   'menu.item.wine.red.cielo_cabernet.description': 'Yoğun, karanfil',
   'menu.item.wine.red.cielo_cabernet.flavor': '',

   // Menu Items - Wine - White
   'menu.item.wine.white.vinkara_bornova.name':
      'Vinkara-Bornova Misketi Dömi Sek',
   'menu.item.wine.white.vinkara_bornova.description':
      'Yarı tatlı, aromatik, egzotik meyve',
   'menu.item.wine.white.vinkara_bornova.flavor': '',

   'menu.item.wine.white.suvla_narince.name': 'Suvla-Narince Misket',
   'menu.item.wine.white.suvla_narince.description':
      'Aromatik, narenciye, çiçeksi',
   'menu.item.wine.white.suvla_narince.flavor': '',

   'menu.item.wine.white.suvla_chardonnay.name': 'Suvla-Chardonnay Şişe',
   'menu.item.wine.white.suvla_chardonnay.description':
      'Zengin, tropikal meyve, tereyağlı',
   'menu.item.wine.white.suvla_chardonnay.flavor': '',

   'menu.item.wine.white.kayra_allure_crispy.name':
      'Kayra Allure Crispy Chardonnay',
   'menu.item.wine.white.kayra_allure_crispy.description': 'Canlı, mineral',
   'menu.item.wine.white.kayra_allure_crispy.flavor': '',

   'menu.item.wine.white.kayra_allure_sauvignon.name':
      'Kayra Allure Sauvignon Blanc',
   'menu.item.wine.white.kayra_allure_sauvignon.description':
      'Ferah, narenciye',
   'menu.item.wine.white.kayra_allure_sauvignon.flavor': '',

   'menu.item.wine.white.cielo_pinot.name': 'Cielo Pinot Grigio',
   'menu.item.wine.white.cielo_pinot.description': 'Hafif, çiçeksi',
   'menu.item.wine.white.cielo_pinot.flavor': '',

   // Menu Items - Wine - Rose-Blush
   'menu.item.wine.rose_blush.vinkara_minoj.name':
      'Vinkara-Minoj Kalecik Karası',
   'menu.item.wine.rose_blush.vinkara_minoj.description':
      'Yumuşak, kiraz, çiçeksi',
   'menu.item.wine.rose_blush.vinkara_minoj.flavor': '',

   'menu.item.wine.rose_blush.suvla_karasakiz.name': 'Suvla-Karasakız',
   'menu.item.wine.rose_blush.suvla_karasakiz.description':
      'Meyvemsi, kırmızı meyve, hafif baharatlı',
   'menu.item.wine.rose_blush.suvla_karasakiz.flavor': '',

   'menu.item.wine.rose_blush.cielo_blush.name': 'Cielo Pinot Grigio Blush',
   'menu.item.wine.rose_blush.cielo_blush.description': 'Hafif, meyvemsi',
   'menu.item.wine.rose_blush.cielo_blush.flavor': '',

   'menu.item.wine.rose_blush.cielo_rose.name': 'Cielo Pinot Grigio Rose',
   'menu.item.wine.rose_blush.cielo_rose.description': 'Canlı, çiçeksi',
   'menu.item.wine.rose_blush.cielo_rose.flavor': '',

   // Menu Items - Wine - Dark Rose
   'menu.item.wine.dark_rose.suvla_clairet.name': 'Suvla-Clairet Şişe',
   'menu.item.wine.dark_rose.suvla_clairet.description':
      'Canlı, çilek, hafif baharatlı',
   'menu.item.wine.dark_rose.suvla_clairet.flavor':
      'Cabernet Sauvignon, Merlot, Syrah',

   // Menu Items - Gin
   'menu.item.gin.mosaik.name': 'Mosaik Gin',
   'menu.item.gin.mosaik.description': 'Botanik, narenciye, dengeli',
   'menu.item.gin.mosaik.flavor': '',

   'menu.item.gin.tanqueray_london.name': 'Tanqueray London Dry Gin',
   'menu.item.gin.tanqueray_london.description': 'Botanik, narenciye, klasi',
   'menu.item.gin.tanqueray_london.flavor': '',

   'menu.item.gin.tanqueray_ten.name': 'Tanqueray No Ten Gin',
   'menu.item.gin.tanqueray_ten.description': 'Taze, zarif, narenciye',
   'menu.item.gin.tanqueray_ten.flavor': '',

   'menu.item.gin.nikka_coffey.name': 'Nikka Coffey Gin',
   'menu.item.gin.nikka_coffey.description': 'Hafif, çiçeksi, botanik',
   'menu.item.gin.nikka_coffey.flavor': '',

   'menu.item.gin.hendricks.name': "Hendrick's Gin",
   'menu.item.gin.hendricks.description': 'Salatalık, gül, turunçgil',
   'menu.item.gin.hendricks.flavor': '',

   // Menu Items - Tequila
   'menu.item.tequila.volcan.name': 'Volcan De Mi Tierra Blanco',
   'menu.item.tequila.volcan.description': 'Hafif, çiçeksi, narenciye',
   'menu.item.tequila.volcan.flavor': '',

   'menu.item.tequila.casamigos_blanco.name': 'Casamigos Blanco',
   'menu.item.tequila.casamigos_blanco.description': 'Temiz, tatlı, hafif',
   'menu.item.tequila.casamigos_blanco.flavor': '',

   'menu.item.tequila.casamigos_reposado.name': 'Casamigos Reposado',
   'menu.item.tequila.casamigos_reposado.description': 'Vanilya, bal, meşe',
   'menu.item.tequila.casamigos_reposado.flavor': '',

   'menu.item.tequila.casamigos_anejo.name': 'Casamigos Anejo',
   'menu.item.tequila.casamigos_anejo.description': 'Kopleks, karamel, baharat',
   'menu.item.tequila.casamigos_anejo.flavor': '',

   'menu.item.tequila.casamigos_mezcal.name': 'Casamigos Mezcal',
   'menu.item.tequila.casamigos_mezcal.description': 'İsli, bitkisel, kompleks',
   'menu.item.tequila.casamigos_mezcal.flavor': '',

   'menu.item.tequila.don_julio_blanco.name': 'Don Julio Blanco',
   'menu.item.tequila.don_julio_blanco.description': 'Taze narenciye, bitkisel',
   'menu.item.tequila.don_julio_blanco.flavor': '',

   'menu.item.tequila.don_julio_reposado.name': 'Don Julio Reposado',
   'menu.item.tequila.don_julio_reposado.description':
      'Hafif odunsu, baharatlı',
   'menu.item.tequila.don_julio_reposado.flavor': '',

   'menu.item.tequila.don_julio_1942.name': 'Don Julio 1942',
   'menu.item.tequila.don_julio_1942.description': 'Pürüzsüz, vanilya, karamel',
   'menu.item.tequila.don_julio_1942.flavor': '',

   // Menu Items - Vodka
   'menu.item.vodka.ketel_one.name': 'Ketel One Vodka',
   'menu.item.vodka.ketel_one.description': 'Pürüzsüz, saf, hafif',
   'menu.item.vodka.ketel_one.flavor': '',

   'menu.item.vodka.belvedere.name': 'Belvedere Vodka',
   'menu.item.vodka.belvedere.description':
      'Kremamsı, hafif baharatlı, dengeli',
   'menu.item.vodka.belvedere.flavor': '',

   'menu.item.vodka.beluga.name': 'Beluga Noble Vodka',
   'menu.item.vodka.beluga.description': 'Lüks, saf, zarif',
   'menu.item.vodka.beluga.flavor': '',

   'menu.item.vodka.nikka_coffey.name': 'Nıkka Coffey Vodka',
   'menu.item.vodka.nikka_coffey.description': 'Pürüzsüz, saf, dengeli',
   'menu.item.vodka.nikka_coffey.flavor': '',

   // Menu Items - Rum
   'menu.item.rum.captain_white.name': 'Captain Morgan White',
   'menu.item.rum.captain_white.description': 'Hafif, narenciye, tatlı',
   'menu.item.rum.captain_white.flavor': '',

   'menu.item.rum.captain_gold.name': 'Captain Morgan Gold',
   'menu.item.rum.captain_gold.description': 'Karamel, tatlı baharat, yunmuşak',
   'menu.item.rum.captain_gold.flavor': '',

   'menu.item.rum.zacapa.name': 'Zacapa 23 Rum',
   'menu.item.rum.zacapa.description': 'Kompleks, vanilya, kuru meyve',
   'menu.item.rum.zacapa.flavor': '',

   // Menu Items - Cognac
   'menu.item.cognac.hennesy_vs.name': 'Hennesy Vs',
   'menu.item.cognac.hennesy_vs.description': 'Meyvemsi baharatlı, odunsu',
   'menu.item.cognac.hennesy_vs.flavor': '',

   'menu.item.cognac.hennesy_vsop.name': 'Hennesy Vsop',
   'menu.item.cognac.hennesy_vsop.description': 'Yumuşak, vanilya, meşe',
   'menu.item.cognac.hennesy_vsop.flavor': '',

   // Menu Items - Vermouth
   'menu.item.vermouth.lillet_blanc.name': 'Lillet Blanc',
   'menu.item.vermouth.lillet_blanc.description': 'Şirin, narenciye, çiçeksi',
   'menu.item.vermouth.lillet_blanc.flavor': '',

   'menu.item.vermouth.lillet_rose.name': 'Lillet Rose',
   'menu.item.vermouth.lillet_rose.description': 'Hafif, meyvemsi, çiçeksel',
   'menu.item.vermouth.lillet_rose.flavor': '',

   'menu.item.vermouth.garrone_bianco.name': 'Garrone Bianco',
   'menu.item.vermouth.garrone_bianco.description': 'Zeytin, baharatlı, tatlı',
   'menu.item.vermouth.garrone_bianco.flavor': '',

   'menu.item.vermouth.garrone_dry.name': 'Garrone Dry',
   'menu.item.vermouth.garrone_dry.description':
      'Aromatik, kurutulmuş meyve, taze',
   'menu.item.vermouth.garrone_dry.flavor': '',

   'menu.item.vermouth.garrone_rosso.name': 'Garrone Rosso',
   'menu.item.vermouth.garrone_rosso.description': 'Tatlı, narenciye, vanilya',
   'menu.item.vermouth.garrone_rosso.flavor': '',

   // Menu Items - Aquavit
   'menu.item.aquavit.aalborg.name': 'Aalborg Taffel',
   'menu.item.aquavit.aalborg.description': 'Ferah, tuzlu, alkolik',
   'menu.item.aquavit.aalborg.flavor': '',

   // Menu Items - Liqueur
   'menu.item.liqueur.bottega_limoncino.name': 'Bottega Limoncino',
   'menu.item.liqueur.bottega_limoncino.description': 'Taze, limon, tatlı',
   'menu.item.liqueur.bottega_limoncino.flavor': '',

   'menu.item.liqueur.bottega_melon.name': 'Bottega Melon',
   'menu.item.liqueur.bottega_melon.description': 'Meyvemsi, kavun, hafif',
   'menu.item.liqueur.bottega_melon.flavor': '',

   'menu.item.liqueur.bottega_amaro.name': 'Bottega Elixir Amaro',
   'menu.item.liqueur.bottega_amaro.description': 'Bitkisel, acı, dengeli',
   'menu.item.liqueur.bottega_amaro.flavor': '',

   'menu.item.liqueur.bottega_amaretto.name': 'Bottega Amaretto',
   'menu.item.liqueur.bottega_amaretto.description': 'Badem, vanilya, karamel',
   'menu.item.liqueur.bottega_amaretto.flavor': '',

   'menu.item.liqueur.bottega_pistachio.name': 'Bottega Pistachio',
   'menu.item.liqueur.bottega_pistachio.description': 'Fıstık, krema, badem',
   'menu.item.liqueur.bottega_pistachio.flavor': '',

   'menu.item.liqueur.bottega_sambuca.name': 'Bottega Sambuca',
   'menu.item.liqueur.bottega_sambuca.description':
      'Anason, baharat, şekerleme',
   'menu.item.liqueur.bottega_sambuca.flavor': '',

   'menu.item.liqueur.jagermeister.name': 'Jagermeister',
   'menu.item.liqueur.jagermeister.description': 'Bitkisel, baharat, yoğun',
   'menu.item.liqueur.jagermeister.flavor': '',

   'menu.item.liqueur.jagermeister_manifes.name': 'Jagermeister Manifest',
   'menu.item.liqueur.jagermeister_manifes.description':
      'Kompleks, baharat, odunsu',
   'menu.item.liqueur.jagermeister_manifes.flavor': '',

   'menu.item.liqueur.jagermeister_cold_brew.name':
      'Jagermeister Cold Brew Coffee',
   'menu.item.liqueur.jagermeister_cold_brew.description':
      'Amerikan kahvesi, acı, baharatlı',
   'menu.item.liqueur.jagermeister_cold_brew.flavor': '',

   'menu.item.liqueur.garrone_triple_sec.name': 'Garrone Triple Sec',
   'menu.item.liqueur.garrone_triple_sec.description':
      'Turunçgil, tatlı, canlı',
   'menu.item.liqueur.garrone_triple_sec.flavor': '',

   'menu.item.liqueur.malibu.name': 'Malibu',
   'menu.item.liqueur.malibu.description': 'Tropikal, hindistan cevizi, tatlı',
   'menu.item.liqueur.malibu.flavor': '',

   'menu.item.liqueur.kahlua.name': 'Kahlua Coffee Liqueur',
   'menu.item.liqueur.kahlua.description': 'Tatlı, yoğun kahve, vanilya',
   'menu.item.liqueur.kahlua.flavor': '',

   'menu.item.liqueur.campari.name': 'Campari',
   'menu.item.liqueur.campari.description': 'Acı, baharatlı, narenciye',
   'menu.item.liqueur.campari.flavor': '',

   'menu.item.liqueur.koskenkorva.name': 'Koskenkorva Rhubarb',
   'menu.item.liqueur.koskenkorva.description': 'Ekşi, meyveli, ferah',
   'menu.item.liqueur.koskenkorva.flavor': '',

   'menu.item.liqueur.baileys.name': 'Baileys Original Irish Cream',
   'menu.item.liqueur.baileys.description': 'Kremamsı, tatlı, yumuşak',
   'menu.item.liqueur.baileys.flavor': '',

   'menu.item.liqueur.aperol.name': 'Aperol',
   'menu.item.liqueur.aperol.description': 'Hafif acı, turunçgil, canlı',
   'menu.item.liqueur.aperol.flavor': '',

   // Menu Items - Beer
   'menu.item.beer.miller.name': 'Miller',
   'menu.item.beer.miller.description': 'Hafif çiçeksi, baharatlı',
   'menu.item.beer.miller.flavor': 'Şerbetçiotu: Hallertau',

   'menu.item.beer.heineken.name': 'Heineken',
   'menu.item.beer.heineken.description': 'Tropikal meyve, narenciye, çiçeksi',
   'menu.item.beer.heineken.flavor': 'Şerbetçiotu: Citra',

   'menu.item.beer.blanc.name': 'Blanc 1664',
   'menu.item.beer.blanc.description': 'Ferahlatıcı, narenciye, çiçeksi',
   'menu.item.beer.blanc.flavor': 'Şerbetçiotu: Strisselspalt',

   'menu.item.beer.hoegaarden.name': 'Hoegaarden',
   'menu.item.beer.hoegaarden.description':
      'Hafif acılık  ve baharatlı, çiçeksi aromalar',
   'menu.item.beer.hoegaarden.flavor': 'Şerbetçiotu: Saaz',

   'menu.item.beer.bud.name': 'Bud',
   'menu.item.beer.bud.description': 'Hafif, Malt, Tahıl',
   'menu.item.beer.bud.flavor': 'Şerbetçiotu: Saaz',

   // Menu Items - Tonic
   'menu.item.tonic.thomas_henry.name': 'Thomas Henry Tonic',
   'menu.item.tonic.thomas_henry.description': '',
   'menu.item.tonic.thomas_henry.flavor': '',

   'menu.item.tonic.thomas_henry_dry.name': 'Thomas Henry Dry Tonic',
   'menu.item.tonic.thomas_henry_dry.description': '',
   'menu.item.tonic.thomas_henry_dry.flavor': '',

   'menu.item.tonic.thomas_henry_pink.name': 'Thomas Henry Pink Greyfurt',
   'menu.item.tonic.thomas_henry_pink.description': '',
   'menu.item.tonic.thomas_henry_pink.flavor': '',

   'menu.item.tonic.thomas_henry_mango.name': 'Thomas Henry Mystic Mango',
   'menu.item.tonic.thomas_henry_mango.description': '',
   'menu.item.tonic.thomas_henry_mango.flavor': '',

   'menu.item.tonic.thomas_henry_ginger.name': 'Thomas Henry Ginger Ale',
   'menu.item.tonic.thomas_henry_ginger.description': '',
   'menu.item.tonic.thomas_henry_ginger.flavor': '',

   // Menu Items - Soft Drinks
   'menu.item.soft.uludag_su.name': 'Uludağ Su',
   'menu.item.soft.uludag_su.description': '',
   'menu.item.soft.uludag_su.flavor': '',

   'menu.item.soft.uludag_soda.name': 'Uludağ Soda',
   'menu.item.soft.uludag_soda.description': '',
   'menu.item.soft.uludag_soda.flavor': '',

   'menu.item.soft.uludag_premium.name': 'Uludağ Premium Soda',
   'menu.item.soft.uludag_premium.description': '',
   'menu.item.soft.uludag_premium.flavor': '',

   'menu.item.soft.coca_cola.name': 'Coca Cola Şişe',
   'menu.item.soft.coca_cola.description': '',
   'menu.item.soft.coca_cola.flavor': '',

   'menu.item.soft.coca_cola_zero.name': 'Coca Cola Zero Şişe',
   'menu.item.soft.coca_cola_zero.description': '',
   'menu.item.soft.coca_cola_zero.flavor': '',

   'menu.item.soft.sprite.name': 'Sprite Kutu',
   'menu.item.soft.sprite.description': '',
   'menu.item.soft.sprite.flavor': '',

   'menu.item.soft.fuse_tea_peach.name': 'Fuse Tea Şeftali',
   'menu.item.soft.fuse_tea_peach.description': '',
   'menu.item.soft.fuse_tea_peach.flavor': '',

   'menu.item.soft.fuse_tea_lemon.name': 'Fuse Tea Limon',
   'menu.item.soft.fuse_tea_lemon.description': '',
   'menu.item.soft.fuse_tea_lemon.flavor': '',

   'menu.item.soft.redbull.name': 'Red Bull',
   'menu.item.soft.redbull.description': '',
   'menu.item.soft.redbull.flavor': '',

   'menu.item.soft.orange_juice.name': 'Taze Portakal Suyu',
   'menu.item.soft.orange_juice.description': '',
   'menu.item.soft.orange_juice.flavor': '',

   'menu.item.wine.rose_blush.antioche_summer.name':
      'Antioche Summer Vibes (Şişe)',
   'menu.item.wine.rose_blush.antioche_summer.description':
      'Dengeli, kırmızı meyve, baharat',
   'menu.item.wine.rose_blush.antioche_summer.flavor': '',

   // Turkish Main Courses
   'menu.item.food.main_courses.boogie_burger.name':
      'Boogie Burger\n🍔 SADECE SALI 🍔',
   'menu.item.food.main_courses.boogie_burger.description':
      '130 gr Burger Köftesi, Soğan, Iceberg Marul, Domates, Turşu, Ketçap, Hardal',
   'menu.item.food.main_courses.boogie_burger.flavor': '',

   'menu.item.food.main_courses.boogie_boogie_burger.name':
      'Boogie Boogie Burger\n🍔🍔 SADECE SALI 🍔🍔',
   'menu.item.food.main_courses.boogie_boogie_burger.description':
      '260 gr Burger Köftesi, Soğan, Iceberg Marul, Domates, Turşu, Ketçap, Hardal',
   'menu.item.food.main_courses.boogie_boogie_burger.flavor': '',

   'menu.item.food.main_courses.chicky_boom.name': 'Chicky Boom',
   'menu.item.food.main_courses.chicky_boom.description':
      'Şiş Tavuk, Yanık Terayağlı Gnocchi, Kestane Mantarı, Köz Biber Sosu',
   'menu.item.food.main_courses.chicky_boom.flavor': '',

   'menu.item.food.main_courses.dana_antrikot.name': 'Dana Antrikot',
   'menu.item.food.main_courses.dana_antrikot.description':
      'Dana Antrikot, Parmesan ve Kremalı Bebek Patates, Şarap soslu demi glace',
   'menu.item.food.main_courses.dana_antrikot.flavor': '',

   // Turkish Translations
   'menu.item.food.sandwiches.smoked_didiwich.name': 'Smoked Didiwich',
   'menu.item.food.sandwiches.smoked_didiwich.description':
      'Haşhaşlı ekmek, Tütsülenmiş Tiftik Tavuk, Köz Biber Kuru Domates Mayo, Izgara İsli Çerkez Peyniri, Tempura Yeşil Biber',
   'menu.item.food.sandwiches.smoked_didiwich.flavor': '',

   'menu.item.food.main_courses.bosco.name': 'Bosco',
   'menu.item.food.main_courses.bosco.description':
      'Caserecce Makarna, Kestane Mantarı Duxelle, Kavrulmuş Fındık&Leblebi, Kuru İncir, Parmesan',

   'menu.item.whiskey.single.glenlivet_12.name': 'The Glenlivet 12 Years Old',
   'menu.item.whiskey.single.glenlivet_12.description':
      'Meyvemsi, vanilyalı, yumuşak',

   'menu.item.whiskey.blended.jw_gold_label.name': 'Johnnie Walker Gold Label',
   'menu.item.whiskey.blended.jw_gold_label.description': 'Bal, vanilya, duman',

   // Popup Message
   'popup.message': "🎉 BOBO FRANCO'DA BU AY 🎉",

   // Menu Items - Food - Appetizers
   'menu.item.food.appetizers.oro_di_franco.name': 'Oro di Franco',
   'menu.item.food.appetizers.oro_di_franco.description':
      'Izgara Camembert Peyniri, Mevsimsel Eşlikçiler',
   'menu.item.food.appetizers.oro_di_franco.flavor': '',

   'menu.item.food.wines.white_wines.cielo_chardonnay.name': 'Cielo Chardonnay',
   'menu.item.food.wines.white_wines.cielo_chardonnay.description':
      'Tropikal, vanilya, yumuşak',
   'menu.item.food.wines.white_wines.cielo_chardonnay.flavor': '',

   'menu.item.food.wines.white_wines.cielo_pinot_grigio.name':
      'Cielo Pinot Grigio Şişe',
   'menu.item.food.wines.white_wines.cielo_pinot_grigio.description':
      'Narenciye, çiçeksi, ferah',
   'menu.item.food.wines.white_wines.cielo_pinot_grigio.flavor': '',

   'menu.item.food.wines.red_wines.cielo_merlot.name': 'Cielo Merlot Şişe',
   'menu.item.food.wines.red_wines.cielo_merlot.description':
      'Erik, baharat, yuvarlak',
   'menu.item.food.wines.red_wines.cielo_merlot.flavor': '',

   'menu.item.food.wines.rose_wines.cielo_pinot_grigio.name':
      'Cielo Pinot Grigio',
   'menu.item.food.wines.rose_wines.cielo_pinot_grigio.description':
      'Çilek, mineral, canlı',
   'menu.item.food.wines.rose_wines.cielo_pinot_grigio.flavor': '',

   'menu.item.food.appetizers.french_fries.name': 'Patates Kızartması',
   'menu.item.food.appetizers.french_fries.description': '',
   'menu.item.food.appetizers.french_fries.flavor': '',

   'menu.item.food.appetizers.charcuterie_plate.name': 'Şarküteri Tabağı',
   'menu.item.food.appetizers.charcuterie_plate.description': '',
   'menu.item.food.appetizers.charcuterie_plate.flavor': '670',

   'menu.item.food.appetizers.salad.name': 'Salata',
   'menu.item.food.appetizers.salad.description': '',
   'menu.item.food.appetizers.salad.flavor': '',
}

export const en: TranslationKeys = {
   // Navigation
   'nav.home': 'Home',
   'nav.about': 'About',
   'nav.gallery': 'Gallery',

   // Features Section
   'features.atmosphere.title': 'Atmosphere',
   'features.atmosphere.description':
      'A hidden Mediterranean garden in the heart of the city, away from the routine hustle, where style and art meet',
   'features.discover.title': 'Discover',
   'features.discover.description':
      'A green atmosphere wrapped in music and conversation where like-minded souls meet. A gastronomic experience where entertainment and style come together at every moment',

   // Highlight Section
   'highlight.section.title': 'Taste',
   'highlight.section.description':
      'Offering a different taste experience at any time of the day, bobo franco is a thoughtful spot that brings together European aperitif cuisine and refined beverages with Adana hospitality. Here are some of them',

   // Highlight Items
   'highlight.item1.title': 'Truffle Risotto',
   'highlight.item1.description':
      'Our risotto flavored with truffle elegantly reflects the classic taste of southern Italy',
   'highlight.item2.title': "Franco's Calamari",
   'highlight.item2.description':
      "With the authentic fresh calamari of the Mediterranean, you'll feel like you're on a seaside culinary escape",
   'highlight.item3.title': 'The Perfect Espresso Martini',
   'highlight.item3.description':
      'Offers a relaxing pleasure with its intense coffee aroma and light creamy texture',

   // Menu Categories
   'menu.category.food': 'FOOD',
   'menu.category.coffee': 'COFFEE',
   'menu.category.cocktails': 'COCKTAILS',
   'menu.category.whiskey': 'WHISKEY',
   'menu.category.champagne': 'CHAMPAGNE',
   'menu.category.wine': 'WINE',
   'menu.category.gin': 'GIN',
   'menu.category.tequila': 'TEQUILA',
   'menu.category.vodka': 'VODKA',
   'menu.category.rum': 'RUM',
   'menu.category.cognac': 'COGNAC',
   'menu.category.vermouth': 'VERMOUTH',
   'menu.category.aquavit': 'AQUAVIT',
   'menu.category.liqueur': 'LIQUEUR',
   'menu.category.beer': 'BEER',
   'menu.category.tonic': 'TONIC',
   'menu.category.soft': 'SOFT DRINKS',

   // Menu Subcategories - Food
   'menu.subcategory.food.appetizers': 'Appetizers',
   'menu.subcategory.food.sandwiches': 'Sandwiches',
   'menu.subcategory.food.main_courses': 'Main Courses',
   'menu.subcategory.food.desserts': 'Desserts',
   'menu.subcategory.food.breakfast': 'Breakfast (until 14:00)',

   // Menu Subcategories - Coffee
   'menu.subcategory.coffee.hot': 'Hot',
   'menu.subcategory.coffee.cold': 'Cold',

   // Menu Subcategories - Cocktails
   'menu.subcategory.cocktails.classic': 'Classic Cocktails',
   'menu.subcategory.cocktails.twisted': 'Twisted Cocktails',
   'menu.subcategory.cocktails.signature': 'Signature Cocktails',

   // Menu Subcategories - Whiskey
   'menu.subcategory.whiskey.blended': 'Blended Scotch',
   'menu.subcategory.whiskey.single': 'Single Malt',
   'menu.subcategory.whiskey.bourbon': 'Bourbon & Tennessee',
   'menu.subcategory.whiskey.irish': 'Irish',

   // Menu Subcategories - Wine
   'menu.subcategory.wine.red': 'Red Wines',
   'menu.subcategory.wine.white': 'White Wines',
   'menu.subcategory.wine.rose': 'Rose-Blush Wines',
   'menu.subcategory.wine.dark_rose': 'Dark Rose Wines',

   // Events Section
   'events.store.name': 'Store',
   'events.store.description':
      "bobo franco is a multi-purpose creative space in Adana where timeless architecture meets artworks and creativity. bobo franco's store creates a special space where creativity can flourish while offering collectible modern vintage and new furniture pieces, contemporary artworks by local artists, and unique design objects.",
   'events.bar.name': 'Bar',
   'events.bar.description':
      "bobo franco's bar is a meeting point that celebrates art and design while offering delicious drinks. In a modern and cozy atmosphere, you can relax and nourish your creativity with carefully crafted cocktails, coffee specialties, and local flavors.",
   'events.events.name': 'Events',
   'events.events.description':
      "Pop-up parties, workshops, art exhibitions, and design talks organized at bobo franco nourish creativity by bringing together bobo franco members, artists, and art lovers. Whether you're looking for a space to organize an art event or for your creative projects, bobo franco's dynamic and fun atmosphere offers an inspiring experience.",

   // Menu Items - Food - Appetizers
   'menu.item.food.sandwiches.fume_sandwich.name': 'Smoked Sandwich',
   'menu.item.food.sandwiches.fume_sandwich.description':
      'Smoked Beef, Aged Kars Cheese, Hot Pepper Jelly, Potato Chips',
   'menu.item.food.sandwiches.fume_sandwich.flavor': '',

   'menu.item.food.appetizers.chicken_popsicle.name': 'Chicken Popsicle',
   'menu.item.food.appetizers.chicken_popsicle.description':
      'Fine Blend Crispy Chicken-Double Aromatic Mayonnaise-Parmesan',
   'menu.item.food.appetizers.chicken_popsicle.flavor': '',

   'menu.item.food.main_courses.orzo_truffle.name': 'Orzo With Truffle Paste',
   'menu.item.food.main_courses.orzo_truffle.description':
      'Barley Pasta Prepared in the Orzo Style, Truffle Paste',
   'menu.item.food.main_courses.orzo_truffle.flavor': '',

   'menu.item.food.appetizers.levrek_filango.name': 'Sea Bass Filango',
   'menu.item.food.appetizers.levrek_filango.description':
      'Cherry Stem Smoked Open Sea Rod-Caught Sea Bass Slices-Pineapple and Lemon Acid-Aromatic Oils (Not heat treated, served cold)',
   'menu.item.food.appetizers.levrek_filango.flavor': '',

   'menu.item.food.main_courses.topluma_levrek.name': 'Socialized Sea Bass',
   'menu.item.food.main_courses.topluma_levrek.description':
      '120g Boneless Sea Bass Fillet, Baby Potatoes',
   'menu.item.food.main_courses.topluma_levrek.flavor': '',

   'menu.item.food.sandwiches.open_face_tuna.name': 'Open Face Tuna',
   'menu.item.food.sandwiches.open_face_tuna.description':
      'Tuna with Green Onion Mayonnaise, Sunflower Seeds and Poppy Seed Bread, Marinated Tomatoes, Pickled Red Onions',
   'menu.item.food.sandwiches.open_face_tuna.flavor': '',

   // Menu Items - Food - Desserts
   'menu.item.food.desserts.lemon_cheesecake.name': 'Lemon Curd Cheesecake',
   'menu.item.food.desserts.lemon_cheesecake.description':
      'Cheesecake with Lemon Curd',
   'menu.item.food.desserts.lemon_cheesecake.flavor': '',

   'menu.item.food.desserts.paris_brest.name': 'Paris Brest',
   'menu.item.food.desserts.paris_brest.description':
      'Pate a Choux Pastry Filled with Pistachio Pastry Cream',
   'menu.item.food.desserts.paris_brest.flavor': '',

   // Menu Items - Coffee - Hot
   'menu.item.coffee.hot.espresso.name': 'Espresso',
   'menu.item.coffee.hot.espresso.description':
      'Balanced acidity, mild sweetness with notes of hazelnut, coffee, and chocolate.',
   'menu.item.coffee.hot.espresso.flavor': '',

   'menu.item.coffee.hot.americano.name': 'Americano',
   'menu.item.coffee.hot.americano.description':
      'Balanced acidity, mild sweetness with notes of hazelnut, coffee, and chocolate.',
   'menu.item.coffee.hot.americano.flavor': '',

   'menu.item.coffee.hot.coffee_latte.name': 'Coffee Latte',
   'menu.item.coffee.hot.coffee_latte.description':
      'Medium length, sweet and nutty finish.',
   'menu.item.coffee.hot.coffee_latte.flavor': '',

   'menu.item.coffee.hot.cappuccino.name': 'Cappuccino',
   'menu.item.coffee.hot.cappuccino.description':
      'Balanced acidity, mild sweetness with notes of hazelnut, coffee, and chocolate.',
   'menu.item.coffee.hot.cappuccino.flavor': '',

   'menu.item.coffee.hot.flat_white.name': 'Flat White',
   'menu.item.coffee.hot.flat_white.description': '',
   'menu.item.coffee.hot.flat_white.flavor': '',

   'menu.item.coffee.hot.cortado.name': 'Cortado',
   'menu.item.coffee.hot.cortado.description': '',
   'menu.item.coffee.hot.cortado.flavor': '',

   'menu.item.coffee.hot.turkish_coffee.name': 'Turkish Coffee',
   'menu.item.coffee.hot.turkish_coffee.description': '',
   'menu.item.coffee.hot.turkish_coffee.flavor': '',

   'menu.item.coffee.hot.filter_coffee.name': 'Filter Coffee',
   'menu.item.coffee.hot.filter_coffee.description':
      'Balanced acidity, mild sweetness with notes of hazelnut, coffee, and chocolate.',
   'menu.item.coffee.hot.filter_coffee.flavor': '',

   'menu.item.coffee.hot.tea.name': 'Tea',
   'menu.item.coffee.hot.tea.description': '',
   'menu.item.coffee.hot.tea.flavor': '',

   // Menu Items - Coffee - Cold
   'menu.item.coffee.cold.ice_americano.name': 'Ice Americano',
   'menu.item.coffee.cold.ice_americano.description':
      'Notes of hazelnut, light chocolate and caramel.',
   'menu.item.coffee.cold.ice_americano.flavor': '',

   'menu.item.coffee.cold.ice_latte.name': 'Ice Latte',
   'menu.item.coffee.cold.ice_latte.description':
      'Notes of hazelnut, light chocolate and caramel.',
   'menu.item.coffee.cold.ice_latte.flavor': '',

   // Menu Items - Cocktails - Classic
   'menu.item.cocktails.classic.whiskey_sour.name': 'WHISKY SOUR',
   'menu.item.cocktails.classic.whiskey_sour.description':
      'Sour, creamy, balanced.',
   'menu.item.cocktails.classic.whiskey_sour.flavor':
      'JW BLACK LABEL, LEMON JUICE, SUGAR SYRUP, EGG WHITE',

   'menu.item.cocktails.classic.dirty_martini.name': 'DIRTY MARTINI',
   'menu.item.cocktails.classic.dirty_martini.description':
      'Salty, dry, balanced.',
   'menu.item.cocktails.classic.dirty_martini.flavor':
      'TANQUERAY LONDON DRY GIN, GARRONE BIANCO, OLIVE BRINE',

   'menu.item.cocktails.classic.penicillin.name': 'PENICILLIN',
   'menu.item.cocktails.classic.penicillin.description':
      'Smoky, spicy, ginger.',
   'menu.item.cocktails.classic.penicillin.flavor':
      'JW BLACK LABEL, ARDBEG WEE BEASTIE, FRESH GINGER JUICE, LEMON JUICE, PINE HONEY',

   'menu.item.cocktails.classic.gin_basil.name': 'GIN BASIL SMASH',
   'menu.item.cocktails.classic.gin_basil.description': 'Fresh, herbal, sour.',
   'menu.item.cocktails.classic.gin_basil.flavor':
      'TANQUERAY LONDON DRY GIN, FRESH BASIL, SUGAR SYRUP, LEMON JUICE',

   'menu.item.cocktails.classic.espresso_martini.name': 'ESPRESSO MARTINI',
   'menu.item.cocktails.classic.espresso_martini.description':
      'Coffee, creamy, sweet.',
   'menu.item.cocktails.classic.espresso_martini.flavor':
      'KETEL ONE VODKA, KAHLUA COFFEE LIQUEUR, ESPRESSO',

   'menu.item.cocktails.classic.dutch_mule.name': 'DUTCH MULE',
   'menu.item.cocktails.classic.dutch_mule.description':
      'Spicy, fresh, slightly sour.',
   'menu.item.cocktails.classic.dutch_mule.flavor':
      'KETEL ONE VODKA, AALBORG TAFFELL AQUAVIT, LIME JUICE, ANGOSTURA BITTER, GINGER ALE',

   'menu.item.cocktails.classic.mimosa.name': 'MIMOSA',
   'menu.item.cocktails.classic.mimosa.description':
      'Orange, fresh, slightly sweet',
   'menu.item.cocktails.classic.mimosa.flavor':
      'RUFFINO PROSECCO, FRESH ORANGE JUICE',

   'menu.item.cocktails.classic.bellini.name': 'BELLINI',
   'menu.item.cocktails.classic.bellini.description':
      'Peach, sparkling, sweet.',
   'menu.item.cocktails.classic.bellini.flavor':
      'RUFFINO PROSECCO, PEACH PUREE',

   'menu.item.cocktails.classic.tommys_margarita.name': "TOMMY'S MARGARITA",
   'menu.item.cocktails.classic.tommys_margarita.description':
      'Tropical, sour, agave.',
   'menu.item.cocktails.classic.tommys_margarita.flavor':
      'CASAMIGOS BLANCO, AGAVE NECTAR, LIME JUICE',

   'menu.item.cocktails.classic.paloma.name': 'PALOMA',
   'menu.item.cocktails.classic.paloma.description':
      'Fresh, citrus, slightly sweet.',
   'menu.item.cocktails.classic.paloma.flavor':
      'CASAMIGOS BLANCO, GRAPEFRUIT JUICE, LEMON JUICE, AGAVE NECTARIN, PINK GRAPEFRUIT SODA',

   'menu.item.cocktails.classic.negroni.name': 'NEGRONI',
   'menu.item.cocktails.classic.negroni.description':
      'Bitter, spicy, balanced.',
   'menu.item.cocktails.classic.negroni.flavor':
      'TANQUERAY LONDON DRY GIN, CAMPARI BITTER LIQUEUR, GARRONE ROSSO VERMOUTH',

   'menu.item.cocktails.classic.old_cuban.name': 'OLD CUBAN HIGHBALL',
   'menu.item.cocktails.classic.old_cuban.description':
      'Citrus, fresh, sparkling.',
   'menu.item.cocktails.classic.old_cuban.flavor':
      'CAPTAIN MORGAN GOLD RUM, LIME JUICE, SUGAR SYRUP, RUFFINO PROSECCO, ANGOSTURA BITTER',

   // Menu Items - Cocktails - Twisted
   'menu.item.cocktails.twisted.cool_franco_spritz.name': 'COOL FRANCO SPRITZ',
   'menu.item.cocktails.twisted.cool_franco_spritz.description':
      'Fruity, herbal, sparkling',
   'menu.item.cocktails.twisted.cool_franco_spritz.flavor':
      'Infused Sage Strawberry Aperol, Vinkara Demisec Bornova, Dry Tonic Water',

   'menu.item.cocktails.twisted.bobo_star_martini.name': 'BOBO STAR MARTINI',
   'menu.item.cocktails.twisted.bobo_star_martini.description':
      'Tropical, spicy, complex.',
   'menu.item.cocktails.twisted.bobo_star_martini.flavor':
      'JW Black Label, Passion Fruit, Maple, Sumac, Worcestershire, İsot Pepper, Citrus Blend',

   'menu.item.cocktails.twisted.cosalinha.name': 'COSALINHA',
   'menu.item.cocktails.twisted.cosalinha.description': 'Smoky, citrus, sweet.',
   'menu.item.cocktails.twisted.cosalinha.flavor':
      'Casamigos Mezcal, Lime Juice, Pine Honey',

   'menu.item.cocktails.twisted.butterfly_daiquiri.name': 'BUTTERFLY DAIQUIRI',
   'menu.item.cocktails.twisted.butterfly_daiquiri.description':
      'Floral, citrus, slightly sweet',
   'menu.item.cocktails.twisted.butterfly_daiquiri.flavor':
      'Infused Butterfly Tea, Captain Morgan White Rum, Orange Liqueur, Grapefruit and Mandarin Juice',

   'menu.item.cocktails.twisted.rock_n_roll.name': "ROCK'N ROLL",
   'menu.item.cocktails.twisted.rock_n_roll.description': 'Sour, spicy, sweet',
   'menu.item.cocktails.twisted.rock_n_roll.flavor':
      'JW Black Label, Bottega Limoncello, Ginger Juice, Maple Syrup, Lemon Juice, Pink Grapefruit Tonic',

   'menu.item.cocktails.twisted.melon_garibaldi.name': 'MELON GARIBALDI',
   'menu.item.cocktails.twisted.melon_garibaldi.description':
      'Fruity, bitter, slightly sweet',
   'menu.item.cocktails.twisted.melon_garibaldi.flavor':
      'Bottega Melon, Campari, Maple Syrup, Fresh Green Apple Juice, Orange Juice, Lemon Juice, Angostura Bitter',

   'menu.item.cocktails.twisted.cane_coco.name': 'CANE & COCO',
   'menu.item.cocktails.twisted.cane_coco.description': 'Smoky, coffee, citrus',
   'menu.item.cocktails.twisted.cane_coco.flavor':
      'Casamigos Blanco Tequila, Infused Arabica Coffee Cinnamon Malibu, Angostura Bitter, Thomas Henry Mango, Citrus Blend',

   // Menu Items - Cocktails - Signature
   'menu.item.cocktails.signature.monik.name': 'MONIK',
   'menu.item.cocktails.signature.monik.description': 'Citrus, herbal, sweet',
   'menu.item.cocktails.signature.monik.flavor':
      'Ketel One Vodka, Fennel, Jujube, Lemon Juice',

   'menu.item.cocktails.signature.06.name': '06',
   'menu.item.cocktails.signature.06.description': 'Sour, umami',
   'menu.item.cocktails.signature.06.flavor':
      'Tanqueray London Dry Gin, Green Apple Sorrel, Citrus Blend, Saponaria',

   'menu.item.cocktails.signature.01.name': '01',
   'menu.item.cocktails.signature.01.description': 'Sour, clear, strong',
   'menu.item.cocktails.signature.01.flavor':
      'Fat Washed Casamigos Blanco Tequila, Clear Quince Juice, Clear Lemon Juice, Sweet Tomato, Dill',

   'menu.item.cocktails.signature.02.name': '02',
   'menu.item.cocktails.signature.02.description': 'Sweet, spicy',
   'menu.item.cocktails.signature.02.flavor':
      'JW Black Label, Mango, Jalapeno, Tarragon, Citrus Blend, Soapwort',

   'menu.item.cocktails.signature.05.name': '05',
   'menu.item.cocktails.signature.05.description': 'Sweet, balanced',
   'menu.item.cocktails.signature.05.flavor':
      'Bulliet Bourbon, Bottega Amaro Elixir, Cherry, Salted Almonds, Roasted Terebinth, Soapwort, Citrus Blend',

   'menu.item.cocktails.signature.07.name': '07',
   'menu.item.cocktails.signature.07.description': 'Sweet, spicy',
   'menu.item.cocktails.signature.07.flavor':
      'Singleton 12 of Dufftown, Bottega Melon, Blackberry, Celery, Star Anise, Citrus Blend',

   'menu.item.cocktails.signature.03.name': '03',
   'menu.item.cocktails.signature.03.description': 'Fruity, bitter',
   'menu.item.cocktails.signature.03.flavor':
      'Fatwashed Casamigos Blanco Cherry Raspberry Cocoa, Campari, Clear Quince Juice, Soda',

   // Menu Items - Whiskey - Blended Scotch
   'menu.item.whiskey.blended.dimple_golden.name': 'Dimple Golden Selection',
   'menu.item.whiskey.blended.dimple_golden.description':
      'Smooth, citrus, light',
   'menu.item.whiskey.blended.dimple_golden.flavor': '',

   'menu.item.whiskey.blended.jw_black.name': 'Johnnie Walker Black Label',
   'menu.item.whiskey.blended.jw_black.description': 'Smoky, fruity, balanced',
   'menu.item.whiskey.blended.jw_black.flavor': '',

   'menu.item.whiskey.blended.jw_double_black.name':
      'Johnnie Walker Double Black',
   'menu.item.whiskey.blended.jw_double_black.description':
      'Intense, woody, spicy',
   'menu.item.whiskey.blended.jw_double_black.flavor': '',

   'menu.item.whiskey.blended.jw_blue.name': 'Johnnie Walker Blue Label',
   'menu.item.whiskey.blended.jw_blue.description': 'Smooth, luxurious, deep',
   'menu.item.whiskey.blended.jw_blue.flavor': '',

   'menu.item.whiskey.blended.jw_king_george.name':
      'Johnnie Walker Sons King George',
   'menu.item.whiskey.blended.jw_king_george.description':
      'Intense, elegant, smoky',
   'menu.item.whiskey.blended.jw_king_george.flavor': '',

   'menu.item.whiskey.blended.jw_18.name': 'Johnnie Walker Aged 18 YO',
   'menu.item.whiskey.blended.jw_18.description': 'Smooth, rich, caramel',
   'menu.item.whiskey.blended.jw_18.flavor': '',

   // Menu Items - Whiskey - Single Malt
   'menu.item.whiskey.single.singleton_12.name':
      'Singleton of Dufftown 12 Years Old',
   'menu.item.whiskey.single.singleton_12.description': 'Peach, vanilla, light',
   'menu.item.whiskey.single.singleton_12.flavor': '',

   'menu.item.whiskey.single.ardbeg.name': 'Ardbeg Wee Beastie',
   'menu.item.whiskey.single.ardbeg.description': 'Smoky, strong, intense',
   'menu.item.whiskey.single.ardbeg.flavor': '',

   'menu.item.whiskey.single.glenmorangie.name':
      'Glenmorangie Triple Cask Reserve',
   'menu.item.whiskey.single.glenmorangie.description':
      'Peach, sweet spice, woody',
   'menu.item.whiskey.single.glenmorangie.flavor': '',

   'menu.item.whiskey.single.cardhu_gold.name': 'Cardhu Gold Reserve',
   'menu.item.whiskey.single.cardhu_gold.description': '',
   'menu.item.whiskey.single.cardhu_gold.flavor': '',

   'menu.item.whiskey.single.talisker.name': 'Talisker 10 YO',
   'menu.item.whiskey.single.talisker.description': 'Smoky, peppery, sea',
   'menu.item.whiskey.single.talisker.flavor': '',

   'menu.item.whiskey.single.singleton_15.name': 'Singleton 15 YO',
   'menu.item.whiskey.single.singleton_15.description': '',
   'menu.item.whiskey.single.singleton_15.flavor': '',

   'menu.item.whiskey.single.cardhu_15.name': 'Cardhu 15 YO',
   'menu.item.whiskey.single.cardhu_15.description': 'Honey, apple, sweet',
   'menu.item.whiskey.single.cardhu_15.flavor': '',

   'menu.item.whiskey.single.oban.name': 'Oban 10 Years Old',
   'menu.item.whiskey.single.oban.description': 'Sea, citrus, lightly smoky',
   'menu.item.whiskey.single.oban.flavor': '',

   'menu.item.whiskey.single.mortlach.name': 'Mortlach 16 Years Old',
   'menu.item.whiskey.single.mortlach.description': 'Complex, meaty, spicy',
   'menu.item.whiskey.single.mortlach.flavor': '',

   'menu.item.whiskey.single.clynelish_12.name': 'Clynelish 12 Years Old',
   'menu.item.whiskey.single.clynelish_12.description':
      'Beeswax, tropical, spice',
   'menu.item.whiskey.single.clynelish_12.flavor': '',

   'menu.item.whiskey.single.clynelish_14.name': 'Clynelish 14 Years Old',
   'menu.item.whiskey.single.clynelish_14.description':
      'Beeswax, tropical, spice',
   'menu.item.whiskey.single.clynelish_14.flavor': '',

   'menu.item.whiskey.single.cardhu_16.name': 'Cardhu 16 YO',
   'menu.item.whiskey.single.cardhu_16.description': '',
   'menu.item.whiskey.single.cardhu_16.flavor': '',

   'menu.item.whiskey.single.cameronbridge.name': 'Cameronbridge 26 YO',
   'menu.item.whiskey.single.cameronbridge.description': '',
   'menu.item.whiskey.single.cameronbridge.flavor': '',

   'menu.item.whiskey.single.nikka_malt.name': 'Nikka Coffey Malt',
   'menu.item.whiskey.single.nikka_malt.description': 'Sweet, creamy, spicy',
   'menu.item.whiskey.single.nikka_malt.flavor': '',

   'menu.item.whiskey.single.nikka_grain.name': 'Nikka Coffey Grain',
   'menu.item.whiskey.single.nikka_grain.description': 'Sweet, ginger creamy',
   'menu.item.whiskey.single.nikka_grain.flavor': '',

   'menu.item.whiskey.single.caol_ila.name': 'Caol Ila 12 YO',
   'menu.item.whiskey.single.caol_ila.description': 'Turbo, lemon, smoky',
   'menu.item.whiskey.single.caol_ila.flavor': '',

   // Menu Items - Whiskey - Bourbon & Tennessee
   'menu.item.whiskey.bourbon.jack_daniels.name': "Jack Daniel's No. 7",
   'menu.item.whiskey.bourbon.jack_daniels.description': 'Sweet, smoky, light',
   'menu.item.whiskey.bourbon.jack_daniels.flavor': '',

   'menu.item.whiskey.bourbon.gentleman_jack.name': 'Gentleman Jack',
   'menu.item.whiskey.bourbon.gentleman_jack.description':
      'Smooth, vanilla, spicy',
   'menu.item.whiskey.bourbon.gentleman_jack.flavor': '',

   'menu.item.whiskey.bourbon.bulleit.name': 'Bulleit Bourbon',
   'menu.item.whiskey.bourbon.bulleit.description': 'Caramel, spice, vanilla',
   'menu.item.whiskey.bourbon.bulleit.flavor': '',

   'menu.item.whiskey.bourbon.woodford.name': 'Woodford Reserve',
   'menu.item.whiskey.bourbon.woodford.description': 'Spicy, caramel, dry',
   'menu.item.whiskey.bourbon.woodford.flavor': '',

   // Menu Items - Whiskey - Irish
   'menu.item.whiskey.irish.bushmills.name': 'Bushmills Originial',
   'menu.item.whiskey.irish.bushmills.description': 'Light, fruity, smooth',
   'menu.item.whiskey.irish.bushmills.flavor': '',

   'menu.item.whiskey.irish.bushmills_10.name': 'Bushmills 10 YO',
   'menu.item.whiskey.irish.bushmills_10.description': 'Honey, vanilla, citrus',
   'menu.item.whiskey.irish.bushmills_10.flavor': '',

   'menu.item.whiskey.irish.bushmills_black.name': 'Bushmills Black',
   'menu.item.whiskey.irish.bushmills_black.description':
      'Complex, oak, fruity',
   'menu.item.whiskey.irish.bushmills_black.flavor': '',

   // About Us Page
   'about.title': 'About Us',
   'about.paragraph1':
      'Bobo Franco, with its menu consisting of refined interpretations of Mediterranean coastal cuisine, helps you start your day and refresh your soul in its secret garden located in the city center, with its coffee and breakfast-focused menu during daytime hours.',
   'about.paragraph2':
      'The evening menu consists of select delicacies that you can share with your friends, accompanying your delicious cocktails.',
   'about.paragraph3':
      'You are invited to a unique atmosphere at bobo, which reflects the essence of the Mediterranean and serves all day in the middle of a unique and relaxing secret garden built on story and experience.',

   // Menu Items - Champagne
   'menu.item.champagne.chandon_garden.name': 'Chandon Garden Spritz',
   'menu.item.champagne.chandon_garden.description': 'Spicy, orange, herbal.',
   'menu.item.champagne.chandon_garden.flavor': 'Chardonnay, Pinot Noir',

   'menu.item.champagne.chandon_brut.name': 'Chandon Brut Sparkling Wine',
   'menu.item.champagne.chandon_brut.description': 'Fresh, citrus, mineral',
   'menu.item.champagne.chandon_brut.flavor':
      'Chardonnay, Pinot Noir, Pinot Meunier',

   'menu.item.champagne.ruffino.name': 'Ruffino Prosecco',
   'menu.item.champagne.ruffino.description': 'Light, citrus, floral.',
   'menu.item.champagne.ruffino.flavor': 'Glera',

   'menu.item.champagne.moet_imperial.name':
      'Moet & Chandon Imperial Brut Champagne Şişe',
   'menu.item.champagne.moet_imperial.description': 'Lively, fruity, floral',
   'menu.item.champagne.moet_imperial.flavor':
      'Pinot Noir, Pinot Meunier, Chardonnay',

   'menu.item.champagne.moet_rose.name':
      'Moet & Chandon Rose Imperial Champagne Şişe',
   'menu.item.champagne.moet_rose.description':
      'Strawberry, pomegranate, spice',
   'menu.item.champagne.moet_rose.flavor':
      'Pinot Noir, Pinot Meunier, Chardonnay',

   // Menu Items - Wine - Red
   'menu.item.wine.red.vinkara_okuzgozu.name': 'Vinkara-Öküzgözü',
   'menu.item.wine.red.vinkara_okuzgozu.description':
      'Lively, red fruit, light spice',
   'menu.item.wine.red.vinkara_okuzgozu.flavor': '',

   'menu.item.wine.red.suvla_papaz.name': 'Suvla-Papaz Karası',
   'menu.item.wine.red.suvla_papaz.description': 'Soft, fruity, spicy',
   'menu.item.wine.red.suvla_papaz.flavor': '',

   'menu.item.wine.red.suvla_reserve.name':
      'Suvla-Reserve Cabernet Sauvignon Şişe',
   'menu.item.wine.red.suvla_reserve.description':
      'Intense, black fruit, earthy',
   'menu.item.wine.red.suvla_reserve.flavor': '',

   'menu.item.wine.red.kayra_terra.name':
      'Kayra Terra Varietal Öküzgözü Boğazkere',
   'menu.item.wine.red.kayra_terra.description': 'Balanced, spicy',
   'menu.item.wine.red.kayra_terra.flavor': '',

   'menu.item.wine.red.cielo_merlot.name': 'Cielo Merlot',
   'menu.item.wine.red.cielo_merlot.description': 'Soft, red fruit',
   'menu.item.wine.red.cielo_merlot.flavor': '',

   'menu.item.wine.red.cielo_cabernet.name': 'Cielo Cabernet Sauvignon',
   'menu.item.wine.red.cielo_cabernet.description': 'Intense, clove',
   'menu.item.wine.red.cielo_cabernet.flavor': '',

   // Menu Items - Wine - White
   'menu.item.wine.white.vinkara_bornova.name':
      'Vinkara-Bornova Misketi Semi-Dry',
   'menu.item.wine.white.vinkara_bornova.description':
      'Semi-sweet, aromatic, exotic fruit',
   'menu.item.wine.white.vinkara_bornova.flavor': '',

   'menu.item.wine.white.suvla_narince.name': 'Suvla-Narince Misket',
   'menu.item.wine.white.suvla_narince.description': 'Aromatic, citrus, floral',
   'menu.item.wine.white.suvla_narince.flavor': '',

   'menu.item.wine.white.suvla_chardonnay.name': 'Suvla-Chardonnay Bottle',
   'menu.item.wine.white.suvla_chardonnay.description':
      'Rich, tropical fruit, buttery',
   'menu.item.wine.white.suvla_chardonnay.flavor': '',

   'menu.item.wine.white.kayra_allure_crispy.name':
      'Kayra Allure Crispy Chardonnay',
   'menu.item.wine.white.kayra_allure_crispy.description': 'Lively, mineral',
   'menu.item.wine.white.kayra_allure_crispy.flavor': '',

   'menu.item.wine.white.kayra_allure_sauvignon.name':
      'Kayra Allure Sauvignon Blanc',
   'menu.item.wine.white.kayra_allure_sauvignon.description': 'Fresh, citrus',
   'menu.item.wine.white.kayra_allure_sauvignon.flavor': '',

   'menu.item.wine.white.cielo_pinot.name': 'Cielo Pinot Grigio',
   'menu.item.wine.white.cielo_pinot.description': 'Light, floral',
   'menu.item.wine.white.cielo_pinot.flavor': '',

   // Menu Items - Wine - Rose-Blush
   'menu.item.wine.rose_blush.vinkara_minoj.name':
      'Vinkara-Minoj Kalecik Karası',
   'menu.item.wine.rose_blush.vinkara_minoj.description':
      'Soft, cherry, floral',
   'menu.item.wine.rose_blush.vinkara_minoj.flavor': '',

   'menu.item.wine.rose_blush.suvla_karasakiz.name': 'Suvla-Karasakız',
   'menu.item.wine.rose_blush.suvla_karasakiz.description':
      'Fruity, red fruit, lightly spiced',
   'menu.item.wine.rose_blush.suvla_karasakiz.flavor': '',

   'menu.item.wine.rose_blush.cielo_blush.name': 'Cielo Pinot Grigio Blush',
   'menu.item.wine.rose_blush.cielo_blush.description': 'Light, fruity',
   'menu.item.wine.rose_blush.cielo_blush.flavor': '',

   'menu.item.wine.rose_blush.cielo_rose.name': 'Cielo Pinot Grigio Rose',
   'menu.item.wine.rose_blush.cielo_rose.description': 'Lively, floral',
   'menu.item.wine.rose_blush.cielo_rose.flavor': '',

   // Menu Items - Wine - Dark Rose
   'menu.item.wine.dark_rose.suvla_clairet.name': 'Suvla-Clairet Bottle',
   'menu.item.wine.dark_rose.suvla_clairet.description':
      'Lively, strawberry, lightly spiced',
   'menu.item.wine.dark_rose.suvla_clairet.flavor':
      'Cabernet Sauvignon, Merlot, Syrah',

   // Menu Items - Gin
   'menu.item.gin.mosaik.name': 'Mosaik Gin',
   'menu.item.gin.mosaik.description': 'Botanical, citrus, balanced',
   'menu.item.gin.mosaik.flavor': '',

   'menu.item.gin.tanqueray_london.name': 'Tanqueray London Dry Gin',
   'menu.item.gin.tanqueray_london.description': 'Botanical, citrus, classic',
   'menu.item.gin.tanqueray_london.flavor': '',

   'menu.item.gin.tanqueray_ten.name': 'Tanqueray No Ten Gin',
   'menu.item.gin.tanqueray_ten.description': 'Fresh, elegant, citrus',
   'menu.item.gin.tanqueray_ten.flavor': '',

   'menu.item.gin.nikka_coffey.name': 'Nikka Coffey Gin',
   'menu.item.gin.nikka_coffey.description': 'Light, floral, botanical',
   'menu.item.gin.nikka_coffey.flavor': '',

   'menu.item.gin.hendricks.name': "Hendrick's Gin",
   'menu.item.gin.hendricks.description': 'Cucumber, rose, citrus',
   'menu.item.gin.hendricks.flavor': '',

   // Menu Items - Tequila
   'menu.item.tequila.volcan.name': 'Volcan De Mi Tierra Blanco',
   'menu.item.tequila.volcan.description': 'Light, floral, citrus',
   'menu.item.tequila.volcan.flavor': '',

   'menu.item.tequila.casamigos_blanco.name': 'Casamigos Blanco',
   'menu.item.tequila.casamigos_blanco.description': 'Clean, sweet, light',
   'menu.item.tequila.casamigos_blanco.flavor': '',

   'menu.item.tequila.casamigos_reposado.name': 'Casamigos Reposado',
   'menu.item.tequila.casamigos_reposado.description': 'Vanilla, honey, oak',
   'menu.item.tequila.casamigos_reposado.flavor': '',

   'menu.item.tequila.casamigos_anejo.name': 'Casamigos Anejo',
   'menu.item.tequila.casamigos_anejo.description': 'Complex, caramel, spice',
   'menu.item.tequila.casamigos_anejo.flavor': '',

   'menu.item.tequila.casamigos_mezcal.name': 'Casamigos Mezcal',
   'menu.item.tequila.casamigos_mezcal.description': 'Smoky, herbal, complex',
   'menu.item.tequila.casamigos_mezcal.flavor': '',

   'menu.item.tequila.don_julio_blanco.name': 'Don Julio Blanco',
   'menu.item.tequila.don_julio_blanco.description': 'Fresh citrus, herbal',
   'menu.item.tequila.don_julio_blanco.flavor': '',

   'menu.item.tequila.don_julio_reposado.name': 'Don Julio Reposado',
   'menu.item.tequila.don_julio_reposado.description': 'Lightly woody, spicy',
   'menu.item.tequila.don_julio_reposado.flavor': '',

   'menu.item.tequila.don_julio_1942.name': 'Don Julio 1942',
   'menu.item.tequila.don_julio_1942.description': 'Smooth, vanilla, caramel',
   'menu.item.tequila.don_julio_1942.flavor': '',

   // Menu Items - Vodka
   'menu.item.vodka.ketel_one.name': 'Ketel One Vodka',
   'menu.item.vodka.ketel_one.description': 'Smooth, pure, light',
   'menu.item.vodka.ketel_one.flavor': '',

   'menu.item.vodka.belvedere.name': 'Belvedere Vodka',
   'menu.item.vodka.belvedere.description':
      'Kremamsı, hafif baharatlı, dengeli',
   'menu.item.vodka.belvedere.flavor': '',

   'menu.item.vodka.beluga.name': 'Beluga Noble Vodka',
   'menu.item.vodka.beluga.description': 'Luxurious, pure, elegant',
   'menu.item.vodka.beluga.flavor': '',

   'menu.item.vodka.nikka_coffey.name': 'Nıkka Coffey Vodka',
   'menu.item.vodka.nikka_coffey.description': 'Smooth, pure, balanced',
   'menu.item.vodka.nikka_coffey.flavor': '',

   // Menu Items - Rum
   'menu.item.rum.captain_white.name': 'Captain Morgan White',
   'menu.item.rum.captain_white.description': 'Light, citrus, sweet',
   'menu.item.rum.captain_white.flavor': '',

   'menu.item.rum.captain_gold.name': 'Captain Morgan Gold',
   'menu.item.rum.captain_gold.description': 'Caramel, sweet spice, smooth',
   'menu.item.rum.captain_gold.flavor': '',

   'menu.item.rum.zacapa.name': 'Zacapa 23 Rum',
   'menu.item.rum.zacapa.description': 'Complex, vanilla, dried fruit',
   'menu.item.rum.zacapa.flavor': '',

   // Menu Items - Cognac
   'menu.item.cognac.hennesy_vs.name': 'Hennesy Vs',
   'menu.item.cognac.hennesy_vs.description': 'Fruity, spicy, woody',
   'menu.item.cognac.hennesy_vs.flavor': '',

   'menu.item.cognac.hennesy_vsop.name': 'Hennesy Vsop',
   'menu.item.cognac.hennesy_vsop.description': 'Smooth, vanilla, oak',
   'menu.item.cognac.hennesy_vsop.flavor': '',

   // Menu Items - Vermouth
   'menu.item.vermouth.lillet_blanc.name': 'Lillet Blanc',
   'menu.item.vermouth.lillet_blanc.description': 'Sweet, citrus, floral',
   'menu.item.vermouth.lillet_blanc.flavor': '',

   'menu.item.vermouth.lillet_rose.name': 'Lillet Rose',
   'menu.item.vermouth.lillet_rose.description': 'Light, fruity, floral',
   'menu.item.vermouth.lillet_rose.flavor': '',

   'menu.item.vermouth.garrone_bianco.name': 'Garrone Bianco',
   'menu.item.vermouth.garrone_bianco.description': 'Olive, spicy, sweet',
   'menu.item.vermouth.garrone_bianco.flavor': '',

   'menu.item.vermouth.garrone_dry.name': 'Garrone Dry',
   'menu.item.vermouth.garrone_dry.description': 'Aromatic, dried fruit, fresh',
   'menu.item.vermouth.garrone_dry.flavor': '',

   'menu.item.vermouth.garrone_rosso.name': 'Garrone Rosso',
   'menu.item.vermouth.garrone_rosso.description': 'Sweet, citrus, vanilla',
   'menu.item.vermouth.garrone_rosso.flavor': '',

   // Menu Items - Aquavit
   'menu.item.aquavit.aalborg.name': 'Aalborg Taffel',
   'menu.item.aquavit.aalborg.description': 'Fresh, salty, alcoholic',
   'menu.item.aquavit.aalborg.flavor': '',

   // Menu Items - Liqueur
   'menu.item.liqueur.bottega_limoncino.name': 'Bottega Limoncino',
   'menu.item.liqueur.bottega_limoncino.description': 'Fresh, lemon, sweet',
   'menu.item.liqueur.bottega_limoncino.flavor': '',

   'menu.item.liqueur.bottega_melon.name': 'Bottega Melon',
   'menu.item.liqueur.bottega_melon.description': 'Fruity, melon, light',
   'menu.item.liqueur.bottega_melon.flavor': '',

   'menu.item.liqueur.bottega_amaro.name': 'Bottega Elixir Amaro',
   'menu.item.liqueur.bottega_amaro.description': 'Herbal, bitter, balanced',
   'menu.item.liqueur.bottega_amaro.flavor': '',

   'menu.item.liqueur.bottega_amaretto.name': 'Bottega Amaretto',
   'menu.item.liqueur.bottega_amaretto.description': 'Almond, vanilla, caramel',
   'menu.item.liqueur.bottega_amaretto.flavor': '',

   'menu.item.liqueur.bottega_pistachio.name': 'Bottega Pistachio',
   'menu.item.liqueur.bottega_pistachio.description':
      'Pistachio, cream, almond',
   'menu.item.liqueur.bottega_pistachio.flavor': '',

   'menu.item.liqueur.bottega_sambuca.name': 'Bottega Sambuca',
   'menu.item.liqueur.bottega_sambuca.description':
      'Anason, baharat, şekerleme',
   'menu.item.liqueur.bottega_sambuca.flavor': '',

   'menu.item.liqueur.jagermeister.name': 'Jagermeister',
   'menu.item.liqueur.jagermeister.description': 'Herbal, spice, intense',
   'menu.item.liqueur.jagermeister.flavor': '',

   'menu.item.liqueur.jagermeister_manifes.name': 'Jagermeister Manifest',
   'menu.item.liqueur.jagermeister_manifes.description':
      'Complex, spice, woody',
   'menu.item.liqueur.jagermeister_manifes.flavor': '',

   'menu.item.liqueur.jagermeister_cold_brew.name':
      'Jagermeister Cold Brew Coffee',
   'menu.item.liqueur.jagermeister_cold_brew.description':
      'American coffee, bitter, spicy',
   'menu.item.liqueur.jagermeister_cold_brew.flavor': '',

   'menu.item.liqueur.garrone_triple_sec.name': 'Garrone Triple Sec',
   'menu.item.liqueur.garrone_triple_sec.description': 'Citrus, sweet, lively',
   'menu.item.liqueur.garrone_triple_sec.flavor': '',

   'menu.item.liqueur.malibu.name': 'Malibu',
   'menu.item.liqueur.malibu.description': 'Tropical, coconut, sweet',
   'menu.item.liqueur.malibu.flavor': '',

   'menu.item.liqueur.kahlua.name': 'Kahlua Coffee Liqueur',
   'menu.item.liqueur.kahlua.description': 'Sweet, intense coffee, vanilla',
   'menu.item.liqueur.kahlua.flavor': '',

   'menu.item.liqueur.campari.name': 'Campari',
   'menu.item.liqueur.campari.description': 'Bitter, spicy, citrus',
   'menu.item.liqueur.campari.flavor': '',

   'menu.item.liqueur.koskenkorva.name': 'Koskenkorva Rhubarb',
   'menu.item.liqueur.koskenkorva.description': 'Sour, fruity, fresh',
   'menu.item.liqueur.koskenkorva.flavor': '',

   'menu.item.liqueur.baileys.name': 'Baileys Original Irish Cream',
   'menu.item.liqueur.baileys.description': 'Creamy, sweet, smooth',
   'menu.item.liqueur.baileys.flavor': '',

   'menu.item.liqueur.aperol.name': 'Aperol',
   'menu.item.liqueur.aperol.description': 'Lightly bitter, citrus, lively',
   'menu.item.liqueur.aperol.flavor': '',

   // Menu Items - Beer
   'menu.item.beer.miller.name': 'Miller',
   'menu.item.beer.miller.description': 'Light floral, spicy',
   'menu.item.beer.miller.flavor': 'Şerbetçiotu: Hallertau',

   'menu.item.beer.heineken.name': 'Heineken',
   'menu.item.beer.heineken.description': 'Tropical fruit, citrus, floral',
   'menu.item.beer.heineken.flavor': 'Şerbetçiotu: Citra',

   'menu.item.beer.blanc.name': 'Blanc 1664',
   'menu.item.beer.blanc.description': 'Ferahlatıcı, narenciye, çiçeksi',
   'menu.item.beer.blanc.flavor': 'Şerbetçiotu: Strisselspalt',

   'menu.item.beer.hoegaarden.name': 'Hoegaarden',
   'menu.item.beer.hoegaarden.description':
      'Hafif acılık  ve baharatlı, çiçeksi aromalar',
   'menu.item.beer.hoegaarden.flavor': 'Şerbetçiotu: Saaz',

   'menu.item.beer.bud.name': 'Bud',
   'menu.item.beer.bud.description': 'Light, Malt, Grain',
   'menu.item.beer.bud.flavor': 'Hops: Saaz',

   // Menu Items - Tonic
   'menu.item.tonic.thomas_henry.name': 'Thomas Henry Tonic',
   'menu.item.tonic.thomas_henry.description': '',
   'menu.item.tonic.thomas_henry.flavor': '',

   'menu.item.tonic.thomas_henry_dry.name': 'Thomas Henry Dry Tonic',
   'menu.item.tonic.thomas_henry_dry.description': '',
   'menu.item.tonic.thomas_henry_dry.flavor': '',

   'menu.item.tonic.thomas_henry_pink.name': 'Thomas Henry Pink Greyfurt',
   'menu.item.tonic.thomas_henry_pink.description': '',
   'menu.item.tonic.thomas_henry_pink.flavor': '',

   'menu.item.tonic.thomas_henry_mango.name': 'Thomas Henry Mystic Mango',
   'menu.item.tonic.thomas_henry_mango.description': '',
   'menu.item.tonic.thomas_henry_mango.flavor': '',

   'menu.item.tonic.thomas_henry_ginger.name': 'Thomas Henry Ginger Ale',
   'menu.item.tonic.thomas_henry_ginger.description': '',
   'menu.item.tonic.thomas_henry_ginger.flavor': '',

   // Menu Items - Soft Drinks
   'menu.item.soft.uludag_su.name': 'Uludağ Water',
   'menu.item.soft.uludag_su.description': '',
   'menu.item.soft.uludag_su.flavor': '',

   'menu.item.soft.uludag_soda.name': 'Uludağ Soda',
   'menu.item.soft.uludag_soda.description': '',
   'menu.item.soft.uludag_soda.flavor': '',

   'menu.item.soft.uludag_premium.name': 'Uludağ Premium Soda',
   'menu.item.soft.uludag_premium.description': '',
   'menu.item.soft.uludag_premium.flavor': '',

   'menu.item.soft.coca_cola.name': 'Coca Cola Bottle',
   'menu.item.soft.coca_cola.description': '',
   'menu.item.soft.coca_cola.flavor': '',

   'menu.item.soft.coca_cola_zero.name': 'Coca Cola Zero Bottle',
   'menu.item.soft.coca_cola_zero.description': '',
   'menu.item.soft.coca_cola_zero.flavor': '',

   'menu.item.soft.sprite.name': 'Sprite Can',
   'menu.item.soft.sprite.description': '',
   'menu.item.soft.sprite.flavor': '',

   'menu.item.soft.fuse_tea_peach.name': 'Fuse Tea Peach',
   'menu.item.soft.fuse_tea_peach.description': '',
   'menu.item.soft.fuse_tea_peach.flavor': '',

   'menu.item.soft.fuse_tea_lemon.name': 'Fuse Tea Lemon',
   'menu.item.soft.fuse_tea_lemon.description': '',
   'menu.item.soft.fuse_tea_lemon.flavor': '',

   'menu.item.soft.redbull.name': 'Red Bull',
   'menu.item.soft.redbull.description': '',
   'menu.item.soft.redbull.flavor': '',

   'menu.item.soft.orange_juice.name': 'Fresh Orange Juice',
   'menu.item.soft.orange_juice.description': '',
   'menu.item.soft.orange_juice.flavor': '',

   'menu.item.wine.rose_blush.antioche_summer.name':
      'Antioche Summer Vibes (Bottle)',
   'menu.item.wine.rose_blush.antioche_summer.description':
      'Balanced, red fruits, spices',
   'menu.item.wine.rose_blush.antioche_summer.flavor': '',

   // English Main Courses
   'menu.item.food.main_courses.boogie_burger.name':
      'Boogie Burger\n🍔 ONLY ON TUESDAYS 🍔',
   'menu.item.food.main_courses.boogie_burger.description':
      '130 gr Burger Patty, Onion, Iceberg Lettuce, Tomato, Pickle, Ketchup, Mustard',
   'menu.item.food.main_courses.boogie_burger.flavor': '',

   'menu.item.food.main_courses.boogie_boogie_burger.name':
      'Boogie Boogie Burger\n🍔🍔 ONLY ON TUESDAYS 🍔🍔',
   'menu.item.food.main_courses.boogie_boogie_burger.description':
      '260 gr Burger Patty, Onion, Iceberg Lettuce, Tomato, Pickle, Ketchup, Mustard',
   'menu.item.food.main_courses.boogie_boogie_burger.flavor': '',

   'menu.item.food.main_courses.chicky_boom.name': 'Chicky Boom',
   'menu.item.food.main_courses.chicky_boom.description':
      'Chicken Skewer, Burnt Butter Gnocchi, Chestnut Mushroom, Roasted Pepper Sauce',
   'menu.item.food.main_courses.chicky_boom.flavor': '',

   'menu.item.food.main_courses.dana_antrikot.name': 'Beef Ribeye',
   'menu.item.food.main_courses.dana_antrikot.description':
      'Beef Ribeye, Parmesan and Creamy Baby Potatoes, Wine sauce demi-glace',
   'menu.item.food.main_courses.dana_antrikot.flavor': '',

   // English Translations
   'menu.item.food.sandwiches.smoked_didiwich.name': 'Smoked Didiwich',
   'menu.item.food.sandwiches.smoked_didiwich.description':
      'Poppy Seed Bread, Smoked Shredded Chicken, Roasted Pepper Sun-Dried Tomato Mayo, Grilled Smoked Circassian Cheese, Tempura Green Pepper',
   'menu.item.food.sandwiches.smoked_didiwich.flavor': '',

   'menu.item.food.main_courses.bosco.name': 'Bosco',
   'menu.item.food.main_courses.bosco.description':
      'Caserecce Pasta, Chestnut Mushroom Duxelle, Roasted Hazelnuts & Chickpeas, Dried Figs, Parmesan',

   'menu.item.whiskey.single.glenlivet_12.name': 'The Glenlivet 12 Years Old',
   'menu.item.whiskey.single.glenlivet_12.description':
      'Fruity, vanilla, smooth',

   'menu.item.whiskey.blended.jw_gold_label.name': 'Johnnie Walker Gold Label',
   'menu.item.whiskey.blended.jw_gold_label.description':
      'Honey, vanilla, smoke',

   // Popup Message
   'popup.message': '🎉 THIS MONTH AT BOBO FRANCO 🎉',

   // Menu Items - Food - Appetizers
   'menu.item.food.appetizers.oro_di_franco.name': 'Oro di Franco',
   'menu.item.food.appetizers.oro_di_franco.description':
      'Grilled Camembert Cheese, Seasonal Accompaniments',
   'menu.item.food.appetizers.oro_di_franco.flavor': '',

   'menu.item.food.wines.white_wines.cielo_chardonnay.name': 'Cielo Chardonnay',
   'menu.item.food.wines.white_wines.cielo_chardonnay.description':
      'Tropical, vanilla, smooth',
   'menu.item.food.wines.white_wines.cielo_chardonnay.flavor': '',

   'menu.item.food.wines.white_wines.cielo_pinot_grigio.name':
      'Cielo Pinot Grigio Bottle',
   'menu.item.food.wines.white_wines.cielo_pinot_grigio.description':
      'Citrus, floral, fresh',
   'menu.item.food.wines.white_wines.cielo_pinot_grigio.flavor': '',

   'menu.item.food.wines.red_wines.cielo_merlot.name': 'Cielo Merlot Bottle',
   'menu.item.food.wines.red_wines.cielo_merlot.description':
      'Plum, spice, round',
   'menu.item.food.wines.red_wines.cielo_merlot.flavor': '',

   'menu.item.food.wines.rose_wines.cielo_pinot_grigio.name':
      'Cielo Pinot Grigio',
   'menu.item.food.wines.rose_wines.cielo_pinot_grigio.description':
      'Strawberry, mineral, lively',
   'menu.item.food.wines.rose_wines.cielo_pinot_grigio.flavor': '',

   'menu.item.food.appetizers.french_fries.name': 'French Fries',
   'menu.item.food.appetizers.french_fries.description': '',
   'menu.item.food.appetizers.french_fries.flavor': '',

   'menu.item.food.breakfast.cheese_omelette.name': 'Cheese Omelette',
   'menu.item.food.breakfast.cheese_omelette.description': '',
   'menu.item.food.breakfast.cheese_omelette.flavor': '',

   'menu.item.food.breakfast.pastrami_omelette.name': 'Pastrami Omelette',
   'menu.item.food.breakfast.pastrami_omelette.description': '',
   'menu.item.food.breakfast.pastrami_omelette.flavor': '',

   'menu.item.food.breakfast.sausage_omelette.name': 'Frankfurter Omelette',
   'menu.item.food.breakfast.sausage_omelette.description': '',
   'menu.item.food.breakfast.sausage_omelette.flavor': '',

   'menu.item.food.appetizers.charcuterie_plate.name': 'Charcuterie Plate',
   'menu.item.food.appetizers.charcuterie_plate.description': '',
   'menu.item.food.appetizers.charcuterie_plate.flavor': '670',

   'menu.item.food.appetizers.salad.name': 'Salad',
   'menu.item.food.appetizers.salad.description': '',
   'menu.item.food.appetizers.salad.flavor': '',
}

export const translations = {
   tr,
   en,
}
