import { FC, useRef, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import barVideo from '../assets/bar_video.mp4'
import mainVideo from '../assets/main_video.mp4'
import { toZonedTime } from 'date-fns-tz'

// Keyframes for letter animation
const fadeIn = keyframes`
   0% {
      opacity: 0;
      transform: translateY(20px);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
`

// Styled components
const Section = styled.section`
   color: white;
   position: relative;
   overflow: hidden;
   height: 100vh;
   touch-action: pan-y pinch-zoom; /* Allow vertical scrolling and pinch zoom, but prevent horizontal */
`

const VideoWrapper = styled.div`
   width: 100%;
   height: 100%;
   position: relative;

   video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      position: absolute;
      z-index: 0;
      transition: opacity 0.5s ease-in-out;
   }

   .hidden-video {
      opacity: 0;
      pointer-events: none;
      z-index: -1;
   }

   .visible-video {
      opacity: 1;
      z-index: 0;
   }
`

const GradientOverlay = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
   );
   z-index: 1;
`

const ContentWrapper = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: 2;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
`

const Bobo = styled.div`
   font-family: 'Sitka Small', serif;
   font-size: 5rem;
   line-height: 1;
   position: relative;
   margin-bottom: 10rem;
   display: flex;
   right: 2rem;
   user-select: none;
   span {
      display: inline-block;
      opacity: 0;
      animation: ${fadeIn} 0.5s forwards;
   }

   span:nth-child(1) {
      animation-delay: 0s;
   }
   span:nth-child(2) {
      animation-delay: 1.5s;
   }
   span:nth-child(3) {
      animation-delay: 0.2s;
   }
   span:nth-child(4) {
      animation-delay: 1.5s;
   }

   @media (min-width: 768px) {
      font-size: 7rem;
   }
`

const Franco = styled.div`
   font-family: 'Sitka Small', serif;
   font-size: 5rem;
   position: absolute;
   top: 3.7rem; /* Adjusts the vertical position below 'bobo' */
   left: 3.7rem; /* Adjusts the horizontal offset to be slightly to the right */
   display: flex;

   @media (min-width: 768px) {
      padding-top: 2.5rem; /* md:pt-10 */
      padding-bottom: 3.25rem; /* md:pb-13 */
   }

   span {
      display: inline-block;
      opacity: 0;
      animation: ${fadeIn} 0.5s forwards;
   }

   span:nth-child(1) {
      animation-delay: 0.4s;
   }
   span:nth-child(2) {
      animation-delay: 0.5s;
   }
   span:nth-child(3) {
      animation-delay: 0.6s;
   }
   span:nth-child(4) {
      animation-delay: 0.7s;
   }
   span:nth-child(5) {
      animation-delay: 0.8s;
   }
   span:nth-child(6) {
      animation-delay: 1.5s;
   }

   @media (min-width: 768px) {
      font-size: 7rem;
   }
`

interface HeroSectionProps {}

const HeroSection: FC<HeroSectionProps> = () => {
   const mainVideoRef = useRef<HTMLVideoElement>(null)
   const barVideoRef = useRef<HTMLVideoElement>(null)
   const [isBarVideo, setIsBarVideo] = useState(false)

   // Determine the current time in Turkey
   const getTurkeyTime = () => {
      const now = new Date()
      const timeZone = 'Europe/Istanbul'
      return toZonedTime(now, timeZone)
   }

   useEffect(() => {
      const turkeyTime = getTurkeyTime()
      const hours = turkeyTime.getHours()

      if (hours >= 19 || hours < 7) {
         // 7 PM to 7 AM
         setIsBarVideo(true)
      } else {
         // 7 AM to 7 PM
         setIsBarVideo(false)
      }
   }, [])

   return (
      <Section>
         <GradientOverlay />
         <VideoWrapper>
            {isBarVideo ? (
               <video
                  ref={barVideoRef}
                  autoPlay
                  loop
                  muted
                  playsInline
                  preload="auto"
                  className="visible-video">
                  <source src={barVideo} type="video/mp4" />
               </video>
            ) : (
               <video
                  ref={mainVideoRef}
                  autoPlay
                  loop
                  muted
                  playsInline
                  preload="auto"
                  className="visible-video">
                  <source src={mainVideo} type="video/mp4" />
               </video>
            )}
         </VideoWrapper>

         <ContentWrapper>
            <Bobo>
               <span>b</span>
               <span>o</span>
               <span>b</span>
               <span>o</span>
               <Franco>
                  <span>f</span>
                  <span>r</span>
                  <span>a</span>
                  <span>n</span>
                  <span>c</span>
                  <span>o</span>
               </Franco>
            </Bobo>
         </ContentWrapper>
      </Section>
   )
}

export default HeroSection
